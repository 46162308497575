export const middleName = [
  'АБАКУМОВИЧ',
  'АБАКУМІВНА',
  'АБРАМОВИЧ',
  'АБРАМІВНА',
  'АВГУСТОВИЧ',
  'АВГУСТІВНА',
  'АВГУСТИНОВИЧ',
  'АВГУСТИНІВНА',
  'АВРАМОВИЧ',
  'АВРАМІВНА',
  'АГАПОВИЧ',
  'АГАПІВНА',
  'АГАФОНОВИЧ',
  'АГАФОНІВНА',
  'АДРІАНОВИЧ',
  'АДРІАНІВНА',
  'АЗАРОВИЧ',
  'АЗАРІВНА',
  'АЛЬБЕРТОВИЧ',
  'АЛЬБЕРТІВНА',
  'АМВРОСОВИЧ',
  'АМВРОСІЇВНА',
  'АНАСТАСОВИЧ',
  'АНАСТАСІВНА',
  'АНАТОЛІЙОВИЧ',
  'АНАТОЛІЇВНА',
  'АНДРІАНОВИЧ',
  'АНДРІАНІВНА',
  'АНДРІЙОВИЧ',
  'АНДРІЇВНА',
  'АНДРОНИКОВИЧ',
  'АНДРОНИКІВНА',
  'АНТИПОВИЧ',
  'АНТИПІВНА',
  'АНТОНОВИЧ',
  'АНТОНІВНА',
  'АНТОНІНОВИЧ',
  'АНТОНІНІВНА',
  'АПОЛЛІНАРОВИЧ',
  'АПОЛЛІНАРІЇВНА',
  'АПОЛЛОНОВИЧ',
  'АПОЛЛОНІВНА',
  'АПОЛЛОНІЙОВИЧ',
  'АПОЛЛОНІЇВНА',
  'АРКАДІЙОВИЧ',
  'АРКАДІЇВНА',
  'АРСЕНОВИЧ',
  'АРСЕНІВНА',
  'АРСЕНІЙОВИЧ',
  'АРСЕНІЇВНА',
  'АРСЕНТІЙОВИЧ',
  'АРСЕНТІЇВНА',
  'АРТЕМОВИЧ',
  'АРТЕМІВНА',
  'АРТЕМОНОВИЧ',
  'АРТЕМОНІВНА',
  'АРТУРОВИЧ',
  'АРТУРІВНА',
  'АРХИПОВИЧ',
  'АРХИПІВНА',
  'АФАНАСІЙОВИЧ',
  'АФАНАСІЇВНА',
  'БЕНЕДИКТОВИЧ',
  'БЕНЕДИКТІВНА',
  'БОГДАНОВИЧ',
  'БОГДАНІВНА',
  'БОГУСЛАВОВИЧ',
  'БОГУСЛАВІВНА',
  'БОЛЕСЛАВОВИЧ',
  'БОЛЕСЛАВІВНА',
  'БОРИСОВИЧ',
  'БОРИСІВНА',
  'БОРИСЛАВОВИЧ',
  'БОРИСЛАВІВНА',
  'БРОНІСЛАВОВИЧ',
  'БРОНІСЛАВІВНА',
  'ВАДИМОВИЧ',
  'ВАДИМІВНА',
  'ВАКУЛОВИЧ',
  'ВАКУЛІВНА',
  'ВАЛЕНТИНОВИЧ',
  'ВАЛЕНТИНІВНА',
  'ВАЛЕРІАНОВИЧ',
  'ВАЛЕРІАНІВНА',
  'ВАЛЕРІЙОВИЧ',
  'ВАЛЕРІЇВНА',
  'ВАРЛАМОВИЧ',
  'ВАРЛАМІВНА',
  'ВАРФОЛОМІЙОВИЧ',
  'ВАРФОЛОМІЇВНА',
  'ВАСИЛЬОВИЧ',
  'ВАСИЛІВНА',
  'ВЕНЕДИКТОВИЧ',
  'ВЕНЕДИКТІВНА',
  'ВЕНІАМІНОВИЧ',
  'ВЕНІАМІНІВНА',
  'ВЕРЕМІЙОВИЧ',
  'ВЕРЕМІЇВНА',
  'ВІКЕНТОВИЧ',
  'ВІКЕНТІВНА',
  'ВІКТОРОВИЧ',
  'ВІКТОРІВНА',
  'ВІССАРІОНОВИЧ',
  'ВІССАРІОНІВНА',
  'ВІТАЛІЙОВИЧ',
  'ВІТАЛІЇВНА',
  'ВІТОЛЬДОВИЧ',
  'ВІТОЛЬДІВНА',
  'ВЛАДОВИЧ',
  'ВЛАДІВНА',
  'ВЛАДИСЛАВОВИЧ',
  'ВЛАДИСЛАВІВНА',
  'ВЛАСОВИЧ',
  'ВЛАСІВНА',
  'ВОЛОДИМИРОВИЧ',
  'ВОЛОДИМИРІВНА',
  'ВСЕВОЛОДОВИЧ',
  'ВСЕВОЛОДІВНА',
  'ВСЕСЛАВОВИЧ',
  'ВСЕСЛАВІВНА',
  'В\'ЯЧЕСЛАВОВИЧ',
  'В\'ЯЧЕСЛАВІВНА',
  'ГАВРИЛОВИЧ',
  'ГАВРИЛІВНА',
  'ГАЛАКТІОНОВИЧ',
  'ГАЛАКТІОНІВНА',
  'ГАРАСИМОВИЧ',
  'ГАРАСИМІВНА',
  'ГЕННАДІЙОВИЧ',
  'ГЕННАДІЇВНА',
  'ГЕОРГІЙОВИЧ',
  'ГЕОРГІЇВНА',
  'ГЕРАСИМОВИЧ',
  'ГЕРАСИМІВНА',
  'ГЕРМАНОВИЧ',
  'ГЕРМАНІВНА',
  'ГЛІБОВИЧ',
  'ГЛІБІВНА',
  'ГНАТОВИЧ',
  'ГНАТІВНА',
  'ГОРДІЙОВИЧ',
  'ГОРДІЇВНА',
  'ГРИГОРОВИЧ',
  'ГРИГОРІВНА',
  'ДАВИДОВИЧ',
  'ДАВИДІВНА',
  'ДАНИЛОВИЧ',
  'ДАНИЛІВНА',
  'ДАРІЙОВИЧ',
  'ДАРІЇВНА',
  'ДЕМИДОВИЧ',
  'ДЕМИДІВНА',
  'ДЕМ\'ЯНОВИЧ',
  'ДЕМ\'ЯНІВНА',
  'ДЕНИСОВИЧ',
  'ДЕНИСІВНА',
  'ДМИТРОВИЧ',
  'ДМИТРІВНА',
  'ЕДУАРДОВИЧ',
  'ЕДУАРДІВНА',
  'ЕМІЛЬОВИЧ',
  'ЕМІЛІВНА',
  'ЕММАНУЇЛОВИЧ',
  'ЕММАНУЇЛІВНА',
  'ЕРАЗМОВИЧ',
  'ЕРАЗМІВНА',
  'ЄВГЕНОВИЧ',
  'ЄВГЕНІВНА',
  'ЄВГЕНІЙОВИЧ',
  'ЄВГЕНІЇВНА',
  'ЄВДОКИМОВИЧ',
  'ЄВДОКИМІВНА',
  'ЄВЛАМПІЙОВИЧ',
  'ЄВЛАМПІЇВНА',
  'ЄВСЕЙОВИЧ',
  'ЄВСЕЇВНА',
  'ЄВСТАФІЙОВИЧ',
  'ЄВСТАФІЇВНА',
  'ЄВСТАХІЙОВИЧ',
  'ЄВСТАХІЇВНА',
  'ЄГОРОВИЧ',
  'ЄГОРІВНА',
  'ЄЛИЗАРОВИЧ',
  'ЄЛИЗАРІВНА',
  'ЄЛИСЕЙОВИЧ',
  'ЄЛИСЕЇВНА',
  'ЄПІФАНОВИЧ',
  'ЄПІФАНІВНА',
  'ЄРОФЕЙОВИЧ',
  'ЄРОФЕЇВНА',
  'ЄФИМОВИЧ',
  'ЄФИМІВНА',
  'ЄФРЕМОВИЧ',
  'ЄФРЕМІВНА',
  'ЄФРОСИНОВИЧ',
  'ЄФРОСИНІВНА',
  'ЗАХАРОВИЧ',
  'ЗАХАРІВНА',
  'ЗАХАРІЙОВИЧ',
  'ЗАХАРІЇВНА',
  'ЗІНОВІЙОВИЧ',
  'ЗІНОВІЇВНА',
  'ЗОСИМОВИЧ',
  'ЗОСИМІВНА',
  'ІВАНОВИЧ',
  'ІВАНІВНА',
  'ІГНАТОВИЧ',
  'ІГНАТІВНА',
  'ІГОРОВИЧ',
  'ІГОРІВНА',
  'ІЄРОНІМОВИЧ',
  'ІЄРОНІМІВНА',
  'ІЗМАІЛОВИЧ',
  'ІЗМАІЛІВНА',
  'ІЗЯСЛАВОВИЧ',
  'ІЗЯСЛАВІВНА',
  'ІЛАРІЙОВИЧ',
  'ІЛАРІЇВНА',
  'ІЛАРІОНОВИЧ',
  'ІЛАРІОНІВНА',
  'ІЛЛІЧ',
  'ІЛЛІВНА',
  'ІНОКЕНТІЙОВИЧ',
  'ІНОКЕНТІЇВНА',
  'ІОВОВИЧ',
  'ІОВІВНА',
  'ІПАТОВИЧ',
  'ІПАТІВНА',
  'ІПОЛИТОВИЧ',
  'ІПОЛИТІВНА',
  'ІРАКЛІЙОВИЧ',
  'ІРАКЛІЇВНА',
  'ІСАЙОВИЧ',
  'ІСАЇВНА',
  'ІСАКОВИЧ',
  'ІСАКІВНА',
  'ЙОСИПОВИЧ',
  'ЙОСИПІВНА',
  'КАЗИМИРОВИЧ',
  'КАЗИМИРІВНА',
  'КАЛІСТРАТОВИЧ',
  'КАЛІСТРАТІВНА',
  'КАПИТОНОВИЧ',
  'КАПИТОНІВНА',
  'КАПІТОНОВИЧ',
  'КАПІТОНІВНА',
  'КАРПОВИЧ',
  'КАРПІВНА',
  'КАСЯНОВИЧ',
  'КАСЯНІВНА',
  'КИРОВИЧ',
  'КИРІВНА',
  'КИРИЛОВИЧ',
  'КИРИЛІВНА',
  'КІНДРАТОВИЧ',
  'КІНДРАТІВНА',
  'КЛАВДІЙОВИЧ',
  'КЛАВДІЇВНА',
  'КЛИМОВИЧ',
  'КЛИМІВНА',
  'КОРНЕЛІЙОВИЧ',
  'КОРНЕЛІЇВНА',
  'КОСТЯНТИНОВИЧ',
  'КОСТЯНТИНІВНА',
  'КСАВЕРІЙОВИЧ',
  'КСАВЕРІЇВНА',
  'КСЕНОФОНТОВИЧ',
  'КСЕНОФОНТІВНА',
  'КУЗЬМИЧ',
  'КУЗЬМИНІЧНА',
  'КУПРІЯНОВИЧ',
  'КУПРІЯНІВНА',
  'ЛАВРЕНТІЙОВИЧ',
  'ЛАВРЕНТІЇВНА',
  'ЛАВРОВИЧ',
  'ЛАВРІВНА',
  'ЛАЗАРОВИЧ',
  'ЛАЗАРІВНА',
  'ЛАРІОНОВИЧ',
  'ЛАРІОНІВНА',
  'ЛЕВОВИЧ',
  'ЛЕВІВНА',
  'ЛЕОНІДОВИЧ',
  'ЛЕОНІДІВНА',
  'ЛЕОНТІЙОВИЧ',
  'ЛЕОНТІЇВНА',
  'ЛЕСЬОВИЧ',
  'ЛЕСІВНА',
  'ЛУКИЧ',
  'ЛУКІВНА',
  'ЛУК\'ЯНОВИЧ',
  'ЛУК\'ЯНІВНА',
  'ЛЮБОМИРОВИЧ',
  'ЛЮБОМИРІВНА',
  'МАВРОВИЧ',
  'МАВРІВНА',
  'МАВРИКІЙОВИЧ',
  'МАВРИКІЇВНА',
  'МАКАРОВИЧ',
  'МАКАРІВНА',
  'МАКСИМОВИЧ',
  'МАКСИМІВНА',
  'МАКСИМІАНОВИЧ',
  'МАКСИМІАНІВНА',
  'МАНУЇЛОВИЧ',
  'МАНУЇЛІВНА',
  'МАРКЕЛОВИЧ',
  'МАРКЕЛІВНА',
  'МАРКІЯНОВИЧ',
  'МАРКІЯНІВНА',
  'МАРКОВИЧ',
  'МАРКІВНА',
  'МАРТИНОВИЧ',
  'МАРТИНІВНА',
  'МАР\'ЯНОВИЧ',
  'МАР\'ЯНІВНА',
  'МАТВІЙОВИЧ',
  'МАТВІЇВНА',
  'МЕЛЕТІЙОВИЧ',
  'МЕЛЕТІЇВНА',
  'МЕРКУРІЙОВИЧ',
  'МЕРКУРІЇВНА',
  'МЕФОДІЙОВИЧ',
  'МЕФОДІЇВНА',
  'МЕЧИСЛАВОВИЧ',
  'МЕЧИСЛАВІВНА',
  'МИКИТОВИЧ',
  'МИКИТІВНА',
  'МИКОЛАЙОВИЧ',
  'МИКОЛАЇВНА',
  'МИЛОСЛАВОВИЧ',
  'МИЛОСЛАВІВНА',
  'МИРОНОВИЧ',
  'МИРОНІВНА',
  'МИРОСЛАВОВИЧ',
  'МИРОСЛАВІВНА',
  'МИТРОФАНОВИЧ',
  'МИТРОФАНІВНА',
  'МИХАЙЛОВИЧ',
  'МИХАЙЛІВНА',
  'МИХЕЙОВИЧ',
  'МИХЕЇВНА',
  'МОДЕСТОВИЧ',
  'МОДЕСТІВНА',
  'МОЙСЕЙОВИЧ',
  'МОЙСЕЇВНА',
  'МСТИСЛАВОВИЧ',
  'МСТИСЛАВІВНА',
  'МУСІЙОВИЧ',
  'МУСІЇВНА',
  'НАЗАРОВИЧ',
  'НАЗАРІВНА',
  'НАСТАСОВИЧ',
  'НАСТАСІВНА',
  'НАУМОВИЧ',
  'НАУМІВНА',
  'НЕСТІРОВИЧ',
  'НЕСТІРІВНА',
  'НЕСТОРОВИЧ',
  'НЕСТОРІВНА',
  'НИКАНОРОВИЧ',
  'НИКАНОРІВНА',
  'НИЛОВИЧ',
  'НИЛІВНА',
  'НИЧИПІРОВИЧ',
  'НИЧИПІРІВНА',
  'НИЧИПОРОВИЧ',
  'НИЧИПОРІВНА',
  'ОВДІЙОВИЧ',
  'ОВДІЇВНА',
  'ОВЕР\'ЯНОВИЧ',
  'ОВЕР\'ЯНІВНА',
  'ОВРАМОВИЧ',
  'ОВРАМІВНА',
  'ОКСЕНТІЙОВИЧ',
  'ОКСЕНТІЇВНА',
  'ОЛЕГОВИЧ',
  'ОЛЕГІВНА',
  'ОЛЕКСАНДРОВИЧ',
  'ОЛЕКСАНДРІВНА',
  'ОЛЕКСІЙОВИЧ',
  'ОЛЕКСІЇВНА',
  'ОЛЕСЬОВИЧ',
  'ОЛЕСІВНА',
  'ОЛІМПІЙОВИЧ',
  'ОЛІМПІЇВНА',
  'ОМЕЛЯНОВИЧ',
  'ОМЕЛЯНІВНА',
  'ОНИКІЙОВИЧ',
  'ОНИКІЇВНА',
  'ОНИСИМОВИЧ',
  'ОНИСИМІВНА',
  'ОНИСІЙОВИЧ',
  'ОНИСІЇВНА',
  'ОПАНАСОВИЧ',
  'ОПАНАСІВНА',
  'ОРЕСТОВИЧ',
  'ОРЕСТІВНА',
  'ОСИПОВИЧ',
  'ОСИПІВНА',
  'ОСТАПОВИЧ',
  'ОСТАПІВНА',
  'ОХРІМОВИЧ',
  'ОХРІМІВНА',
  'ПАВЛИНОВИЧ',
  'ПАВЛИНІВНА',
  'ПАВЛОВИЧ',
  'ПАВЛІВНА',
  'ПАМФІЛОВИЧ',
  'ПАМФІЛІВНА',
  'ПАНАСОВИЧ',
  'ПАНАСІВНА',
  'ПАНКРАТОВИЧ',
  'ПАНКРАТІВНА',
  'ПАНТЕЛЕЙМОНОВИЧ',
  'ПАНТЕЛЕЙМОНІВНА',
  'ПАНФІЛОВИЧ',
  'ПАНФІЛІВНА',
  'ПАРАМОНОВИЧ',
  'ПАРАМОНІВНА',
  'ПАРФЕНОВИЧ',
  'ПАРФЕНІВНА',
  'ПАХОМОВИЧ',
  'ПАХОМІВНА',
  'ПЕТРОВИЧ',
  'ПЕТРІВНА',
  'ПИЛИПОВИЧ',
  'ПИЛИПІВНА',
  'ПИМІНОВИЧ',
  'ПИМІНІВНА',
  'ПИМОНОВИЧ',
  'ПИМОНІВНА',
  'ПЛАТОНОВИЧ',
  'ПЛАТОНІВНА',
  'ПОЛІКАРПОВИЧ',
  'ПОЛІКАРПІВНА',
  'ПОРФИРОВИЧ',
  'ПОРФИРІВНА',
  'ПОТАПОВИЧ',
  'ПОТАПІВНА',
  'ПРОВОВИЧ',
  'ПРОВІВНА',
  'ПРОКІПОВИЧ',
  'ПРОКІПІВНА',
  'ПРОКОПОВИЧ',
  'ПРОКОПІВНА',
  'ПРОТАСОВИЧ',
  'ПРОТАСІВНА',
  'ПРОХОРОВИЧ',
  'ПРОХОРІВНА',
  'РАФАЇЛОВИЧ',
  'РАФАЇЛІВНА',
  'РИГІРОВИЧ',
  'РИГІРІВНА',
  'РОБЕРТОВИЧ',
  'РОБЕРТІВНА',
  'РОДІОНОВИЧ',
  'РОДІОНІВНА',
  'РОМАНОВИЧ',
  'РОМАНІВНА',
  'РОСТИСЛАВОВИЧ',
  'РОСТИСЛАВІВНА',
  'РУСЛАНОВИЧ',
  'РУСЛАНІВНА',
  'САВОВИЧ',
  'САВІВНА',
  'САВАТІЙОВИЧ',
  'САВАТІЇВНА',
  'САВЕЛІЙОВИЧ',
  'САВЕЛІЇВНА',
  'САМСОНОВИЧ',
  'САМСОНІВНА',
  'СВИРИДОВИЧ',
  'СВИРИДІВНА',
  'СВЯТОПОЛКОВИЧ',
  'СВЯТОПОЛКІВНА',
  'СВЯТОСЛАВОВИЧ',
  'СВЯТОСЛАВІВНА',
  'СЕВАСТІЯНОВИЧ',
  'СЕВАСТІЯНІВНА',
  'СЕВАСТЯНОВИЧ',
  'СЕВАСТЯНІВНА',
  'СЕВЕР\'ЯНОВИЧ',
  'СЕВЕР\'ЯНІВНА',
  'СЕВЕРИНОВИЧ',
  'СЕВЕРИНІВНА',
  'СЕМЕНОВИЧ',
  'СЕМЕНІВНА',
  'СЕРАФИМОВИЧ',
  'СЕРАФИМІВНА',
  'СЕРГІЙОВИЧ',
  'СЕРГІЇВНА',
  'СИДІРОВИЧ',
  'СИДІРІВНА',
  'СИДОРОВИЧ',
  'СИДОРІВНА',
  'СИЛЬВЕСТРОВИЧ',
  'СИЛЬВЕСТРІВНА',
  'СИМОНОВИЧ',
  'СИМОНІВНА',
  'СОЛОМОНОВИЧ',
  'СОЛОМОНІВНА',
  'СОФРОНОВИЧ',
  'СОФРОНІВНА',
  'СПИРИДОНОВИЧ',
  'СПИРИДОНІВНА',
  'СТАНІСЛАВОВИЧ',
  'СТАНІСЛАВІВНА',
  'СТАХОВИЧ',
  'СТАХІВНА',
  'СТЕПАНОВИЧ',
  'СТЕПАНІВНА',
  'ТАРАСОВИЧ',
  'ТАРАСІВНА',
  'ТЕРЕНТІЙОВИЧ',
  'ТЕРЕНТІЇВНА',
  'ТИМОФІЙОВИЧ',
  'ТИМОФІЇВНА',
  'ТИМУРОВИЧ',
  'ТИМУРІВНА',
  'ТИТОВИЧ',
  'ТИТІВНА',
  'ТИХІНОВИЧ',
  'ТИХІНІВНА',
  'ТИХОНОВИЧ',
  'ТИХОНІВНА',
  'ТОДОРОВИЧ',
  'ТОДОРІВНА',
  'ТРИФОНОВИЧ',
  'ТРИФОНІВНА',
  'ТРОХИМОВИЧ',
  'ТРОХИМІВНА',
  'УЛІЯНОВИЧ',
  'УЛІЯНІВНА',
  'УЛЯНОВИЧ',
  'УЛЯНІВНА',
  'УСТИМОВИЧ',
  'УСТИМІВНА',
  'ФАДЕЙОВИЧ',
  'ФАДЕЇВНА',
  'ФЕДІРОВИЧ',
  'ФЕДІРІВНА',
  'ФЕДОРОВИЧ',
  'ФЕДОРІВНА',
  'ФЕДОТОВИЧ',
  'ФЕДОТІВНА',
  'ФЕЛІКСОВИЧ',
  'ФЕЛІКСІВНА',
  'ФЕОДОСІЙОВИЧ',
  'ФЕОДОСІЇВНА',
  'ФЕОДУЛОВИЧ',
  'ФЕОДУЛІВНА',
  'ФЕОКТИСТОВИЧ',
  'ФЕОКТИСТІВНА',
  'ФЕОФАНОВИЧ',
  'ФЕОФАНІВНА',
  'ФЕРАПОНТОВИЧ',
  'ФЕРАПОНТІВНА',
  'ФИЛИМОНОВИЧ',
  'ФИЛИМОНІВНА',
  'ФІЛАРЕТОВИЧ',
  'ФІЛАРЕТІВНА',
  'ФІРСОВИЧ',
  'ФІРСІВНА',
  'ФЛАВІАНОВИЧ',
  'ФЛАВІАНІВНА',
  'ФЛАВІЙОВИЧ',
  'ФЛАВІЇВНА',
  'ФЛОРОВИЧ',
  'ФЛОРІВНА',
  'ФЛОРЕНТИНОВИЧ',
  'ФЛОРЕНТИНІВНА',
  'ФЛОРЕНТІЙОВИЧ',
  'ФЛОРЕНТІЇВНА',
  'ФЛОРІАНОВИЧ',
  'ФЛОРІАНІВНА',
  'ФОКОВИЧ',
  'ФОКІВНА',
  'ФОТІЙОВИЧ',
  'ФОТІЇВНА',
  'ФРОЛОВИЧ',
  'ФРОЛІВНА',
  'ХАРИТОНОВИЧ',
  'ХАРИТОНІВНА',
  'ХОМИЧ',
  'ХОМІВНА',
  'ХРИСАНФОВИЧ',
  'ХРИСАНФІВНА',
  'ХРИСТИАНОВИЧ',
  'ХРИСТИАНІВНА',
  'ХРИСТОФОРОВИЧ',
  'ХРИСТОФОРІВНА',
  'ЮВЕНАЛІЙОВИЧ',
  'ЮВЕНАЛІЇВНА',
  'ЮЛІАНОВИЧ',
  'ЮЛІАНІВНА',
  'ЮЛІЙОВИЧ',
  'ЮЛІЇВНА',
  'ЮЛІЯНОВИЧ',
  'ЮЛІЯНІВНА',
  'ЮРІЙОВИЧ',
  'ЮРІЇВНА',
  'ЮРКОВИЧ',
  'ЮРКІВНА',
  'ЮСТИМОВИЧ',
  'ЮСТИМІВНА',
  'ЮСТИНОВИЧ',
  'ЮСТИНІВНА',
  'ЮХИМОВИЧ',
  'ЮХИМІВНА',
  'ЯВДОКИМОВИЧ',
  'ЯВДОКИМІВНА',
  'ЯВТУХОВИЧ',
  'ЯВТУХІВНА',
  'ЯКИМОВИЧ',
  'ЯКИМІВНА',
  'ЯКОВИЧ',
  'ЯКІВНА',
  'ЯНОВИЧ',
  'ЯНІВНА',
  'ЯНУАРІЙОВИЧ',
  'ЯНУАРІЇВНА',
  'ЯРЕМОВИЧ',
  'ЯРЕМІВНА',
  'ЯРМИЛОВИЧ',
  'ЯРМИЛІВНА',
  'ЯРМОЛОВИЧ',
  'ЯРМОЛІВНА',
  'ЯРОПОЛКОВИЧ',
  'ЯРОПОЛКІВНА',
  'ЯРОСЛАВОВИЧ',
  'ЯРОСЛАВІВНА',
]
