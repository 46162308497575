import { computed } from "vue";
import store from "@/store";
import {Info} from "@/types/Info";

export const useMain = () => {
  const info = computed<Info|undefined>(()=> store.getters.info)
  const template = computed((): string => info.value?.settings?.template ?? process.env.VUE_APP_TEMPLATE ?? '');
  const isGotovo = computed((): boolean => template.value === 'gotovo');

  return {
    template, isGotovo
  }
};
