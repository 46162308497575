import axios, { AxiosRequestConfig } from "axios";
import { getLanguage, getMainApiUrl, getToken } from '@/composable/helpers';

axios.defaults.timeout = 15000;
axios.defaults.timeoutErrorMessage = "timeout";

interface Config {
  headers: {
    Authorization: string;
    lang: string;
  };
  withCredentials: boolean;
}

interface Arguments {
  url: string;
  method: string;
  data?: Record<string, unknown>;
  isGlobalApi?: boolean;
}

export async function request({ url, method, data = {}, isGlobalApi = true }: Arguments): Promise<any> {
  const config: Config = {
    headers: {
      Authorization: getToken(),
      lang: getLanguage()
    },
    withCredentials: true
  };
  const fullUrl = process.env.VUE_APP_SERVER_TYPE === "local"
    ? `${ isGlobalApi ? "/api/terminal-queue-token" : "" }${ url }`
    //? `http://localhost:3001${ isGlobalApi ? "/api/terminal-queue-token" : "" }${ url }`
    : `${ getMainApiUrl() }/terminal-queue-token${ url }`;

  let response;
  switch (method) {
    case "get":
      response = await axios.get(fullUrl, config as AxiosRequestConfig);
      break;
    case "post":
      response = await axios.post(fullUrl, data, config as AxiosRequestConfig);
      break;
    case "put":
      response = await axios.put(fullUrl, data, config as AxiosRequestConfig);
      break;
    case "delete":
      response = await axios.delete(fullUrl, config as AxiosRequestConfig);
      break;
    case "patch":
      response = await axios.patch(fullUrl, data, config as AxiosRequestConfig);
      break;
  }

  return response?.data;
}
