<template>
    <div>
        <Breadcrumbs />
        <Stepper :items="stepperItems" :currentItem="currentStep"/>
        <InputComponent :inputData="inputData"/>
        <SearchInput :inputData="inputData"/>
        <Permission/>
        <Hints :items="hints"/>
    </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
    name: "DataInput",
    props: {
        results: Array,
    },
    components: {
        Breadcrumbs: defineAsyncComponent(() => import('@/components/Text/BreadcrumbsComponent')),
        Stepper: defineAsyncComponent(() => import('@/components/Stepper/StepperComponent')),
        InputComponent: defineAsyncComponent(() => import('@/components/Input/InputComponent')),
        SearchInput: defineAsyncComponent(() => import('@/components/Input/SearchInput')),
        Hints: defineAsyncComponent(() => import('@/components/Hints/HintsComponent')),
        Permission: defineAsyncComponent(() => import('@/components/Permission/PermissionComponent')),
    },
    emits: ['setFooterButtons'],
    data: () => ({
        stepperItems: [
            { id: 0, text: "Прізвище, ім'я, по батькові" },
            { id: 1, text: 'ІПН' },
        ],
        breadcrumbs: ['Головна', 'Платежі за послуги', 'Паспорт', 'Паспорт у формі ID картки', '10 робочих днів', 'Введенні даних'],
        hints: ['Іванов', 'Іванова', 'Іванько', 'Іваненко', 'Іванець', 'Іванченко'],
        inputData: {
            label: 'Введіть ПІБ'
        },
        currentStep: 0,
    }),
    created () {
        this.$emit('setFooterButtons', [
            { text: 'Назад', type: 'back' },
            { text: 'Головна', type: 'main' },
            { text: 'Далі', type: 'next' }
        ])
    }
}
</script>

<style lang="scss" scoped>

</style>