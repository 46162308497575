<template>
  <div class="service-menu-btn"></div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
@import "../../assets/styles/utils/sizes";

.service-menu-btn {
  width: 3.125rem;
  margin: 0 4px;
  @include svgBg($serviceMenu, 1.75rem);
}

@media (min-width: 2000px) {
  .service-menu-btn {
    width: 2.5vw;
    background-size: 2vh;
  }
}
</style>
