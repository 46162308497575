import { GetterTree, ActionTree, MutationTree } from "vuex";
import { gerErrorMessage } from '@/assets/ts/helpers';
import {
  getTQueue,
  getTQueueInfo,
  getTQueueOrganization,
  getTQueueServices,
  setTQueueService,
  getTQueueServicePackages,
  setTQueueServicePackage,
  getTQueueDatePeriods,
  setTQueueDatePeriod,
  getTQueueTimePeriods,
  setTQueueTimePeriod,
  getTQueueForm,
  addQueue, addLiveQueue, printDocument, checkQueue
} from '@/services/terminal.service';
import { Info } from '@/types/Info';
import { Organization } from '@/types/Organization';
import { Service } from '@/types/Service';
import { ServicePackage } from '@/types/ServicePackage';
import { QueueAddResult, QueueCheckResult, Results, Selected } from '@/types/Results';
import { DatePeriod, TimePeriod } from '@/types/Period';
import { DataField } from '@/types/Form';
import { AvailableCurrent } from '@/types/AvailableCurrent';
import { PrintData } from '@/types/Print';
import {StateInterface} from "@/store/index";

/* todo прописать типизацию для queue*/
export interface TerminalStateInterface {
  queue?: any,
  info?: Info,
  organization?: Organization,
  services: Service[],
  servicePackages: ServicePackage[],
  datePeriods: DatePeriod[],
  timePeriods: TimePeriod[],
  fields: DataField[],
  addResult?: QueueAddResult,
  checkResult?: QueueCheckResult,
  results?: Results,
  error: string,
  selected?: Selected,
  availableCurrent?: AvailableCurrent,
}
const state = ():TerminalStateInterface => ({
  services: [],
  servicePackages: [],
  datePeriods: [],
  timePeriods: [],
  fields: [],
  error: '',
});

const mutations: MutationTree<TerminalStateInterface> = {
  SET_QUEUE: (state, value: any) => {
    state.queue = value;
  },
  SET_INFO: (state, info: Info) => {
    state.info = info;
  },
  SET_ORGANIZATION: (state, organization: Organization) => {
    state.organization = organization;
  },
  SET_SERVICES: (state, services: Service[]) => {
    state.services = services;
  },
  SET_SERVICE_PACKAGES: (state, value: ServicePackage[]) => {
    state.servicePackages = value;
  },
  SET_DATE_PERIODS: (state, value: DatePeriod[]) => {
    state.datePeriods = value;
  },
  SET_TIME_PERIODS: (state, value: TimePeriod[]) => {
    state.timePeriods = value;
  },
  SET_FIELDS: (state, fields: DataField[]) => {
    state.fields = fields;
  },
  SET_ADD_RESULT: (state, value: QueueAddResult) => {
    state.addResult = value;
  },
  SET_CHECK_RESULT: (state, value: QueueCheckResult) => {
    state.checkResult = value;
  },
  SET_RESULTS: (state, results: Results) => {
    state.results = results;
  },
  SET_ERROR: (state, error: string) => {
    state.error = error;
  },
  SET_SELECTED: (state, selected: Selected) => {
    state.selected = selected;
  },
  SET_AVAILABLE_CURRENT: (state, availableCurrent: AvailableCurrent) => {
    state.availableCurrent = availableCurrent;
  }
};

const actions: ActionTree<TerminalStateInterface, StateInterface> = {
  async getTQueue({ commit }) {
    try {
      const queue = await getTQueue();
      commit('SET_QUEUE', queue);
      commit('SET_ERROR', '');
    } catch (err) {
      commit('SET_QUEUE', {});
      commit('SET_ERROR', gerErrorMessage(err));
    }
  },
  async getTQueueInfo({ commit }) {
    try {
      const info: Info = await getTQueueInfo();
      commit('SET_INFO', info);
      commit('SET_ERROR', '');
    } catch (err) {
      commit('SET_INFO', {});
      commit('SET_ERROR', gerErrorMessage(err));
    }
  },
  async getTQueueOrganization({ commit }) {
    try {
      const organization: Organization = await getTQueueOrganization();
      commit('SET_ORGANIZATION', organization);
      commit('SET_ERROR', '');
    } catch (err) {
      commit('SET_ORGANIZATION', []);
      commit('SET_ERROR', gerErrorMessage(err));
    }
  },
  async getTQueueServices({ commit }) {
    try {
      const services: Service[] = await getTQueueServices();
      commit('SET_SERVICES', services);
      commit('SET_ERROR', '');
    } catch (err) {
      commit('SET_SERVICES', []);
      commit('SET_ERROR', gerErrorMessage(err));
    }
  },
  async setTQueueService({ commit }, id: number) {
    try {
      const results: Results = await setTQueueService(id);
      commit('SET_RESULTS', results);
      commit('SET_ERROR', '');
    } catch (err) {
      commit('SET_RESULTS', {});
      commit('SET_ERROR', gerErrorMessage(err));
    }
  },
  async getTQueueServicePackages({ commit }) {
    try {
      const servicePackages = await getTQueueServicePackages();
      commit('SET_SERVICE_PACKAGES', servicePackages);
      commit('SET_ERROR', '');
    } catch (err) {
      commit('SET_SERVICE_PACKAGES', []);
      commit('SET_ERROR', gerErrorMessage(err));
    }
  },
  async setTQueueServicePackage({ commit }, id: number) {
    try {
      const results: Results = await setTQueueServicePackage(id);
      commit('SET_RESULTS', results);
      commit('SET_ERROR', '');
    } catch (err) {
      commit('SET_RESULTS', {});
      commit('SET_ERROR', gerErrorMessage(err));
    }
  },
  async getTQueueDatePeriods({ commit }) {
    try {
      const { datePeriods, availableCurrent } = await getTQueueDatePeriods();
      commit('SET_DATE_PERIODS', datePeriods);
      commit('SET_ERROR', '');
      if (availableCurrent) {
        commit('SET_AVAILABLE_CURRENT', availableCurrent);
      }
    } catch (err) {
      commit('SET_DATE_PERIODS', []);
      commit('SET_ERROR', gerErrorMessage(err));
    }
  },
  async setTQueueDatePeriod({ commit }, id: string) {
    try {
      const results: Results = await setTQueueDatePeriod(id);
      commit('SET_RESULTS', results);
      commit('SET_ERROR', '');
    } catch (err) {
      commit('SET_RESULTS', {});
      commit('SET_ERROR', gerErrorMessage(err));
    }
  },
  async getTQueueTimePeriods({ commit }) {
    try {
      const { timePeriods, availableCurrent } = await getTQueueTimePeriods();
      commit('SET_TIME_PERIODS', timePeriods);
      commit('SET_ERROR', '');
      if (availableCurrent) {
        commit('SET_AVAILABLE_CURRENT', availableCurrent);
      }
    } catch (err) {
      commit('SET_TIME_PERIODS', []);
      commit('SET_ERROR', gerErrorMessage(err));
    }
  },
  async setTQueueTimePeriod({ commit }, id: string) {
    try {
      const results: Results = await setTQueueTimePeriod(id);
      commit('SET_RESULTS', results);
      commit('SET_ERROR', '');
    } catch (err) {
      commit('SET_RESULTS', {});
      commit('SET_ERROR', gerErrorMessage(err));
    }
  },
  async getTQueueForm({ commit }) {
    try {
      const { customerDataFields, selected } = await getTQueueForm();
      commit('SET_FIELDS', customerDataFields);
      commit('SET_SELECTED', selected);
      commit('SET_ERROR', '');
    } catch (err) {
      commit('SET_FIELDS', []);
      commit('SET_SELECTED', {});
      commit('SET_ERROR', gerErrorMessage(err));
    }
  },
  async addQueue({ commit }, data) {
    try {
      const result = await addQueue(data);
      commit('SET_ADD_RESULT', result);
      commit('SET_ERROR', result?.error ?? '');
    } catch (err) {
      commit('SET_ADD_RESULT', '');
      commit('SET_ERROR', gerErrorMessage(err));
    }
  },
  async addLiveQueue({ commit }) {
    try {
      const result = await addLiveQueue();
      commit('SET_ADD_RESULT', result);
      commit('SET_ERROR', '');
    } catch (err) {
      commit('SET_ADD_RESULT', {});
      commit('SET_ERROR', gerErrorMessage(err));
    }
  },
  async checkQueue({ commit }) {
    try {
      const result = await checkQueue();
      commit('SET_CHECK_RESULT', result);
      commit('SET_ERROR', result?.error ?? '');
    } catch (err) {
      commit('SET_CHECK_RESULT', {});
      commit('SET_ERROR', gerErrorMessage(err));
    }
  },
  async printDocument({ commit }, body: PrintData) {
    try {
      await printDocument(body);
      commit('SET_ERROR', '');
    } catch (err) {
      commit('SET_ERROR', gerErrorMessage(err));
    }
  },
  setTimePeriods({ commit }, timePeriods: TimePeriod[]) {
    commit('SET_TIME_PERIODS', timePeriods);
  },
  resetError({ commit }) {
    commit('SET_ERROR', '');
  }
};

const getters: GetterTree<TerminalStateInterface, StateInterface> = {
  queue: ({ queue }) => queue,
  info: ({ info }) => info,
  organization: ({ organization }) => organization,
  services: ({ services }) => {
    services.map(item => item.type = 'service');
    return services;
  },
  servicePackages: ({ servicePackages }) => {
    servicePackages.map(item => item.type = 'servicePackage');
    return servicePackages;
  },
  datePeriods: ({ datePeriods }) => datePeriods,
  timePeriods: ({ timePeriods }) => timePeriods,
  fields: ({ fields }) => fields,
  addResult: ({ addResult }) => addResult,
  checkResult: ({ checkResult }) => checkResult,
  results: ({ results }) => results,
  error: ({ error }) => error,
  selected: ({ selected }) => selected,
  availableCurrent: ({ availableCurrent }) => availableCurrent
};

export default {
  mutations,
  actions,
  getters,
  state
};
