export const lastName = [
  'АБАКУМ',
  'АБРАМ',
  'АВГУСТ',
  'АВГУСТА',
  'АВГУСТИН',
  'АВРАМ',
  'АВРОРА',
  'АГАПІЙ',
  'АГАФІЯ',
  'АГАФОН',
  'АГЛАЇДА',
  'АГЛАЯ',
  'АГНІЯ',
  'АГРИПИНА',
  'АДА',
  'АДЕЛАЇДА',
  'АДЕЛІНА',
  'АДЕЛЬ',
  'АДРІАН',
  'АДРІАНА',
  'АЗА',
  'АЗАЛІЯ',
  'АЗАР',
  'АЛЕВТИНА',
  'АЛІНА',
  'АЛІСА',
  'АЛЛА',
  'АЛЬБЕРТ',
  'АЛЬБЕРТИНА',
  'АЛЬБІНА',
  'АМВРОСІЙ',
  'АНАСТАС',
  'АНАСТАСІЯ',
  'АНАТОЛІЙ',
  'АНАТОЛІЯ',
  'АНГЕЛІНА',
  'АНДРІАН',
  'АНДРІАНА',
  'АНДРІЙ',
  'АНДРОНИК',
  'АНЖЕЛА',
  'АНЖЕЛІКА',
  'АННА',
  'АНТИП',
  'АНТОН',
  'АНТОНИДА',
  'АНТОНІН',
  'АНТОНІНА',
  'АНТОНІЯ',
  'АНФІСА',
  'АПОЛЛІНАРІЙ',
  'АПОЛЛІНАРІЯ',
  'АПОЛЛОН',
  'АПОЛЛОНІЙ',
  'АПОЛЛОНІЯ',
  'АРІАДНА',
  'АРКАДІЙ',
  'АРКАДІЯ',
  'АРСЕН',
  'АРСЕНА',
  'АРСЕНІЙ',
  'АРСЕНТІЙ',
  'АРТЕМ',
  'АРТЕМІЯ',
  'АРТЕМОН',
  'АРТУР',
  'АРХИП',
  'АФАНАСІЙ',
  'БЕАТРИСА',
  'БЕЛЛА',
  'БЕНЕДИКТ',
  'БЕРТА',
  'БОГДАН',
  'БОГДАНА',
  'БОГУСЛАВ',
  'БОГУСЛАВА',
  'БОЛЕСЛАВ',
  'БОЛЕСЛАВА',
  'БОРИС',
  'БОРИСЛАВ',
  'БОРИСЛАВА',
  'БРОНІСЛАВ',
  'БРОНІСЛАВА',
  'ВАДИМ',
  'ВАКУЛА',
  'ВАЛЕНТИН',
  'ВАЛЕНТИНА',
  'ВАЛЕРІАН',
  'ВАЛЕРІЙ',
  'ВАЛЕРІЯ',
  'ВАНДА',
  'ВАРВАРА',
  'ВАРЛАМ',
  'ВАРФОЛОМІЙ',
  'ВАСИЛИНА',
  'ВАСИЛЬ',
  'ВАССА',
  'ВЕНЕДИКТ',
  'ВЕНІАМІН',
  'ВЕРЕМІЙ',
  'ВЕРОНІКА',
  'ВІКЕНТ',
  'ВІКТОР',
  'ВІКТОРИНА',
  'ВІКТОРІЯ',
  'ВІОЛА',
  'ВІОЛЕТТА',
  'ВІРА',
  'ВІССАРІОН',
  'ВІТА',
  'ВІТАЛІЙ',
  'ВІТАЛІНА',
  'ВІТАЛІЯ',
  'ВІТОЛЬД',
  'ВЛАД',
  'ВЛАДА',
  'ВЛАДИСЛАВ',
  'ВЛАДИСЛАВА',
  'ВЛАС',
  'ВОЛОДИМИР',
  'ВОЛОДИМИРА',
  'ВСЕВОЛОД',
  'ВСЕСЛАВ',
  'В\'ЯЧЕСЛАВ',
  'ГАВРИЛО',
  'ГАЛАКТІОН',
  'ГАЛИНА',
  'ГАННА',
  'ГАРАСИМ',
  'ГАФІЯ',
  'ГЕЛЕНА',
  'ГЕННАДІЙ',
  'ГЕОРГІЙ',
  'ГЕОРГІНА',
  'ГЕРАСИМ',
  'ГЕРМАН',
  'ГЕРТРУДА',
  'ГЛАФІРА',
  'ГЛИКЕРІЯ',
  'ГЛІБ',
  'ГНАТ',
  'ГОРДІЙ',
  'ГОРПИНА',
  'ГРИГОРІЙ',
  'ДАВИД',
  'ДАНИЛО',
  'ДАРІЙ',
  'ДАРІЯ',
  'ДАР\'Я',
  'ДЕМИД',
  'ДЕМ\'ЯН',
  'ДЕНИС',
  'ДІАНА',
  'ДІНА',
  'ДМИТРО',
  'ДОКІЯ',
  'ДОМНА',
  'ДОМНІКІЯ',
  'ДОРА',
  'ДОРОФЕЯ',
  'ЕВЕЛІНА',
  'ЕДУАРД',
  'ЕЛЕОНОРА',
  'ЕЛЛА',
  'ЕЛЬВІРА',
  'ЕМІЛІЯ',
  'ЕМІЛЬ',
  'ЕММА',
  'ЕММАНУЇЛ',
  'ЕРАЗМ',
  'ЄВА',
  'ЄВГЕН',
  'ЄВГЕНА',
  'ЄВГЕНІЙ',
  'ЄВГЕНІЯ',
  'ЄВДОКИМ',
  'ЄВДОКІЯ',
  'ЄВДОКСІЯ',
  'ЄВЛАМПІЙ',
  'ЄВЛАМПІЯ',
  'ЄВПРАКСІЯ',
  'ЄВСЕЙ',
  'ЄВСТАФІЙ',
  'ЄВСТАХІЙ',
  'ЄГОР',
  'ЄЛИЗАВЕТА',
  'ЄЛИЗАР',
  'ЄЛИСЕЙ',
  'ЄПІФАН',
  'ЄРЕМІЯ',
  'ЄРОФЕЙ',
  'ЄФИМ',
  'ЄФИМІЯ',
  'ЄФРЕМ',
  'ЄФРОСИН',
  'ЄФРОСИНІЯ',
  'ЖАННА',
  'ЗАХАР',
  'ЗАХАРІЙ',
  'ЗІНАЇДА',
  'ЗІНОВІЙ',
  'ЗІНОВІЯ',
  'ЗОСИМ',
  'ЗОЯ',
  'ІВАН',
  'ІВАННА',
  'ІГНАТ',
  'ІГОР',
  'ІДА',
  'ІЄРОНІМ',
  'ІЗАБЕЛЛА',
  'ІЗМАІЛ',
  'ІЗОЛЬДА',
  'ІЗЯСЛАВ',
  'ІЛАРІЙ',
  'ІЛАРІОН',
  'ІЛАРІЯ',
  'ІЛЛЯ',
  'ІЛОНА',
  'ІНГА',
  'ІНЕСА',
  'ІННА',
  'ІНОКЕНТІЙ',
  'ІОВ',
  'ІОНА',
  'ІПАТ',
  'ІПОЛИТ',
  'ІРАЇДА',
  'ІРАКЛІЙ',
  'ІРИНА',
  'ІСАЙ',
  'ІСАК',
  'ІСИДОРА',
  'ЙОСИП',
  'КАЗИМИР',
  'КАЗИМИРА',
  'КАЛИНА',
  'КАЛІСТРАТ',
  'КАМІЛА',
  'КАПИТОЛІНА',
  'КАПИТОН',
  'КАПІТОН',
  'КАРИНА',
  'КАРОЛІНА',
  'КАРПО',
  'КАСЯН',
  'КАТЕРИНА',
  'КИР',
  'КИРИЛО',
  'КІНДРАТ',
  'КІРА',
  'КЛАВДІЙ',
  'КЛАВДІЯ',
  'КЛАРА',
  'КЛЕМЕНТИНА',
  'КЛИМ',
  'КОРНЕЛІЙ',
  'КОРНЕЛІЯ',
  'КОСТЯНТИН',
  'КСАВЕРІЙ',
  'КСЕНІЯ',
  'КСЕНОФОНТ',
  'КУЗЬМА',
  'КУПРІЯН',
  'ЛАВРЕНТІЙ',
  'ЛАВРО',
  'ЛАДА',
  'ЛАЗАР',
  'ЛАРИСА',
  'ЛАРІОН',
  'ЛЕВ',
  'ЛЕОНІД',
  'ЛЕОНТИНА',
  'ЛЕОНТІЙ',
  'ЛЕСЯ',
  'ЛЕСЬ',
  'ЛІАНА',
  'ЛІДІЯ',
  'ЛІЛІАНА',
  'ЛІЛІЯ',
  'ЛІНА',
  'ЛІЯ',
  'ЛУКА',
  'ЛУКЕРІЯ',
  'ЛУК\'ЯН',
  'ЛЮБОВ',
  'ЛЮБОМИР',
  'ЛЮДМИЛА',
  'МАВР',
  'МАВРА',
  'МАВРИКІЙ',
  'МАГДА',
  'МАГДАЛИНА',
  'МАЙЯ',
  'МАКАР',
  'МАКСИМ',
  'МАКСИМІАН',
  'МАКСИМІЛІАН',
  'МАЛАНКА',
  'МАНУЇЛ',
  'МАРГАРИТА',
  'МАРИНА',
  'МАРІЯ',
  'МАРКЕЛ',
  'МАРКІЯН',
  'МАРКО',
  'МАРТИН',
  'МАРФА',
  'МАР\'ЯН',
  'МАР\'ЯНА',
  'МАТВІЙ',
  'МАТИЛЬДА',
  'МЕЛАНІЯ',
  'МЕЛЕТІЙ',
  'МЕЛЕТІЯ',
  'МЕЛІТИНА',
  'МЕРКУРІЙ',
  'МЕФОДІЙ',
  'МЕЧИСЛАВ',
  'МИКИТА',
  'МИКОЛА',
  'МИЛАНА',
  'МИЛОСЛАВ',
  'МИЛОСЛАВА',
  'МИНА',
  'МИРОН',
  'МИРОСЛАВ',
  'МИРОСЛАВА',
  'МИТРОФАН',
  'МИХАЙЛИНА',
  'МИХАЙЛО',
  'МИХЕЙ',
  'МОДЕСТ',
  'МОЙСЕЙ',
  'МОКРИНА',
  'МОТРОНА',
  'МСТИСЛАВ',
  'МУСІЙ',
  'НАДІЯ',
  'НАЗАР',
  'НАСТАС',
  'НАСТАСІЯ',
  'НАТАЛІЯ',
  'НАТАЛЯ',
  'НАУМ',
  'НЕЛЛІ',
  'НЕОНІЛА',
  'НЕСТІР',
  'НЕСТОР',
  'НИКАНОР',
  'НИЛ',
  'НИЧИПІР',
  'НИЧИПОР',
  'НІКА',
  'НІНА',
  'НОННА',
  'НОРА',
  'ОВДІЙ',
  'ОВЕР\'ЯН',
  'ОВРАМ',
  'ОДАРКА',
  'ОКСАНА',
  'ОКСЕНТІЙ',
  'ОЛЕГ',
  'ОЛЕКСАНДР',
  'ОЛЕКСАНДРА',
  'ОЛЕКСАНДРИНА',
  'ОЛЕКСІЙ',
  'ОЛЕНА',
  'ОЛЕСЯ',
  'ОЛЕСЬ',
  'ОЛІМПІАДА',
  'ОЛІМПІЙ',
  'ОЛІМПІЯ',
  'ОЛЯНА',
  'ОЛЬГА',
  'ОМЕЛЯН',
  'ОНИКІЙ',
  'ОНИСИМ',
  'ОНИСІЙ',
  'ОНИСІЯ',
  'ОПАНАС',
  'ОРЕСТ',
  'ОРИНА',
  'ОСИП',
  'ОСТАП',
  'ОХРІМ',
  'ПАВЛА',
  'ПАВЛИН',
  'ПАВЛИНА',
  'ПАВЛО',
  'ПАЛАЖКА',
  'ПАМФІЛ',
  'ПАНАС',
  'ПАНКРАТ',
  'ПАНТЕЛЕЙМОН',
  'ПАНФІЛ',
  'ПАРАМОН',
  'ПАРАСКОВІЯ',
  'ПАРФЕН',
  'ПАХОМ',
  'ПЕЛАГІЯ',
  'ПЕЛАГЕЯ',
  'ПЕТРО',
  'ПИЛИП',
  'ПИМІН',
  'ПИМОН',
  'ПЛАТОН',
  'ПОЛІКАРП',
  'ПОЛІНА',
  'ПОРФИР',
  'ПОТАП',
  'ПРАКСЯ',
  'ПРІСЬКА',
  'ПРОВ',
  'ПРОКІП',
  'ПРОКОП',
  'ПРОТАС',
  'ПРОХОР',
  'ПУЛЬХЕРІЯ',
  'РАДА',
  'РАЇСА',
  'РАФАЇЛ',
  'РАХІЛЬ',
  'РЕГІНА',
  'РЕНАТА',
  'РИГІР',
  'РИММА',
  'РОБЕРТ',
  'РОДІОН',
  'РОЗА',
  'РОЗАЛІЯ',
  'РОКСОЛАНА',
  'РОМАН',
  'РОСТИСЛАВ',
  'РОСТИСЛАВА',
  'РУСЛАН',
  'РУСЛАНА',
  'САБІНА',
  'САВА',
  'САВАТІЙ',
  'САВЕЛІЙ',
  'САЛОМЕЯ',
  'САМСОН',
  'САРА',
  'СВИРИД',
  'СВІТЛАНА',
  'СВЯТОПОЛК',
  'СВЯТОСЛАВ',
  'СВЯТОСЛАВА',
  'СЕВАСТІЯН',
  'СЕВАСТІЯНА',
  'СЕВАСТЯН',
  'СЕВЕР\'ЯН',
  'СЕВЕРИН',
  'СЕВЕРИНА',
  'СЕМЕН',
  'СЕРАФИМ',
  'СЕРАФИМА',
  'СЕРГІЙ',
  'СИДІР',
  'СИДОР',
  'СИДОРА',
  'СИЛЬВЕСТР',
  'СИМОН',
  'СІЛЬВІЯ',
  'СНІЖАНА',
  'СОЛОМІЯ',
  'СОЛОМОН',
  'СОФІЯ',
  'СОФРОН',
  'СПИРИДОН',
  'СТАНІСЛАВ',
  'СТАНІСЛАВА',
  'СТАХ',
  'СТЕЛЛА',
  'СТЕПАН',
  'СТЕПАНИДА',
  'СТЕФАНІЯ',
  'СУСАННА',
  'ТАІСІЯ',
  'ТАЇСІЯ',
  'ТАМАРА',
  'ТАМІЛА',
  'ТАРАС',
  'ТЕРЕЗА',
  'ТЕРЕНТІЙ',
  'ТЕТЯНА',
  'ТИМОФІЙ',
  'ТИМУР',
  'ТИТ',
  'ТИХІН',
  'ТИХОН',
  'ТІНА',
  'ТОДОР',
  'ТОДОРА',
  'ТОМА',
  'ТРИФОН',
  'ТРОХИМ',
  'УЛІЯН',
  'УЛЯН',
  'УЛЯНА',
  'УСТИМ',
  'УСТИНА',
  'ФАДЕЙ',
  'ФАЇНА',
  'ФЕДІР',
  'ФЕДОР',
  'ФЕДОРА',
  'ФЕДОТ',
  'ФЕКЛА',
  'ФЕЛІКС',
  'ФЕОДОСІЙ',
  'ФЕОДОСІЯ',
  'ФЕОДУЛ',
  'ФЕОКТИСТ',
  'ФЕОФАН',
  'ФЕОФАНІЯ',
  'ФЕРАПОНТ',
  'ФИЛИМОН',
  'ФІЛАРЕТ',
  'ФІРС',
  'ФЛАВІАН',
  'ФЛАВІЙ',
  'ФЛОР',
  'ФЛОРЕНТИН',
  'ФЛОРЕНТІЙ',
  'ФЛОРІАН',
  'ФОКА',
  'ФОТІЙ',
  'ФРОЛ',
  'ФРОСИНА',
  'ХАРИТИНА',
  'ХАРИТОН',
  'ХИМА',
  'ХІВРЯ',
  'ХОМА',
  'ХРИСАНФ',
  'ХРИСТИАН',
  'ХРИСТИНА',
  'ХРИСТОФОР',
  'ЦЕЦІЛІЯ',
  'ЮВЕНАЛІЙ',
  'ЮЛІАН',
  'ЮЛІАНА',
  'ЮЛІЙ',
  'ЮЛІЯ',
  'ЮЛІЯН',
  'ЮЛІЯНА',
  'ЮРІЙ',
  'ЮРКО',
  'ЮСТИМ',
  'ЮСТИН',
  'ЮСТИНА',
  'ЮХИМ',
  'ЮХИМА',
  'ЮХИМІЯ',
  'ЯВДОКИМ',
  'ЯВДОХА',
  'ЯВТУХ',
  'ЯДВІГА',
  'ЯКИЛИНА',
  'ЯКИМ',
  'ЯКІВ',
  'ЯН',
  'ЯНА',
  'ЯНІНА',
  'ЯНУАРІЙ',
  'ЯРЕМА',
  'ЯРИНА',
  'ЯРМИЛО',
  'ЯРМОЛА',
  'ЯРОПОЛК',
  'ЯРОСЛАВ',
  'ЯРОСЛАВА',
]
