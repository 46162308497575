import { Key } from "@/types/Keyboard";

export const num: Key[][] = [
    [
        { symbol: '1' },
        { symbol: '2' },
        { symbol: '3' },
        { symbol: '', action: 'backspace' }
    ], [
        { symbol: '4' },
        { symbol: '5' },
        { symbol: '6' },
        { symbol: '', action: 'backward' },
    ], [
        { symbol: '7' },
        { symbol: '8' },
        { symbol: '9' },
        { symbol: '', action: 'forward' },
    ], [
        { symbol: '-' },
        { symbol: '0' },
        { symbol: '.' },
        { symbol: '', action: 'letters' },
    ],
];


