import { RouteRecordRaw } from "vue-router";
import QueueTreeView from "@/views/QueueTreeView.vue";
import QueuePeriodView from "@/views/QueuePeriodView.vue";
import QueueFormView from "@/views/QueueFormView.vue";
import QueueResultsView from "@/views/QueueResultsView.vue";

export const queueRoutes: RouteRecordRaw[]  = [{
    path: '/queue-tree',
    name: 'QueueTreeView',
    component: QueueTreeView
}, {
    path: '/queue-period',
    name: 'QueuePeriodView',
    component: QueuePeriodView
}, {
    path: '/queue-form',
    name: 'QueueFormView',
    component: QueueFormView
}, {
    path: '/queue-results',
    name: 'QueueResultsView',
    component: QueueResultsView
}];