import { computed } from 'vue';
import store from "@/store";

const token = computed((): string => store.getters.token);
const language = computed((): string => store.getters.language);

export const getToken = () => {
  const value = token.value !== '' ? token.value : process.env.VUE_APP_TOKEN ?? '';
  return value?.length ? `Bearer ${value}` : '';
};

export const getLanguage = () => {
  const value = language.value !== '' ? language.value : process.env.VUE_APP_I18N_LOCALE ?? '';
  return value?.length ? value : 'ua';
};

export const getMainApiUrl = () => process.env.NODE_ENV === 'development' ? process.env.VUE_APP_DEVELOPER_SERVER_API : process.env.VUE_APP_SERVER_API;
