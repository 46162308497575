import { AxiosError, AxiosResponse } from "axios";
import i18n from "@/i18n";

export function gerErrorMessage(error: unknown): string {
  if (((error as AxiosError)?.response as AxiosResponse)?.data) {
    const data = ((error as AxiosError).response as AxiosResponse).data;
    if (data.error) {
      return i18n.global.t(`errors.${ data.error }`);
    } else {
      return data?.message ?? '';
    }
  } else if ((error as AxiosError)?.message) {
    return (error as AxiosError).message;
  } else {
    return 'Error';
  }
}