export const ua = {
  account: 'Рахунок',
  uah: 'грн',
  helpline: 'Ми завжди поруч, телефонуйте',
  search: 'Пошук',
  loading: 'Завантаження даних',
  timer_question: 'Ви ще тут?',
  duration: 'Тривалість',
  welcome_title: 'Вітаємо в терміналі самообслуговування!',
  welcome_subtitle: 'Будь ласка, оберіть послугу',
  choose_service: 'Оберіть послугу',
  error: 'Помилка',
  result_ticket_num: 'Номер Вашого талону: {num}',
  ticket_num: 'Номер талону',
  terminal: 'Термінал',
  address: 'Адреса',
  help_line_phone: 'Телефон гарячої лінії',
  approximate_at: 'приблизно о',
  confirmation: 'Підтвердження',
  attention: 'Увага!',
  choose_time: 'Вибір часу',
  free_time: 'Вільний час',
  days: ['Пн', 'Вт',' Ср', 'Чт', 'Пт',' Сб', 'Нд'],
  header: {
    info_button: 'Згода на обробку персональних даних',
    rates_button: 'Інформація та тарифи',
  },
  footer: {
    button_back: 'Назад',
    button_main: 'Головна',
    button_next: 'Далі',
  },
  permission: {
    part_1: 'Надаю згоду на збір, обробку та використання наданих мною персональних даних.',
    part_2: 'З правами суб\'єкта персональних даних ознайомлений(на).',
  },
  button: {
    ok: 'Ок',
    nearest: 'Найближчий час',
    edit: 'Редагувати',
    add_queue: 'Записатись',
    yes: 'Так',
    reload: 'Перезавантажити',
    to_main: 'На головну',
    permission: 'Згода на обробку персональних даних',
    submit_queue_live: 'Підтвердити найближчий час',
    cancel: 'Відміна',
    submit_time: 'Підтвердити час',
    other_time: 'Обрати інший час',
    close: 'Закрити',
    print: 'Роздрукувати талон',
  },
  queue: {
    available_time: 'Доступний час запису на',
    no_available_time: 'Немає доступного часу для запису на',
    fixed: 'На іншу дату та час',
    live_1: 'Поточна черга',
    live_2: '(на сьогодні)',
    no_near_1: 'На даний момент, талони до поточної черги, відсутні',
    no_near_2: 'Будь ласка, оберіть зручний для вас час на іншу дату',
    choose_period: 'Будь ласка, оберіть варіант реєстрації в чергу',
    no_dates: 'Немає доступних дат',
    approximate_time: 'Приблизний час очікування',
    appearance_time: 'Очікується поява талонів через',
    in_queue: 'Осіб в черзі на вибрану послугу',
    current: 'Поточна',
    more_than: 'Більше, ніж',
    live_gotovo_1: 'Час вказано орієнтовно!',
    live_gotovo_2: 'Не залишайте підрозділ, Вас можуть викликати раніше.',
    live_gotovo_3: 'Слідкуйте за чергою на табло.',
    live_gotovo_4: 'Якщо Вас не влаштовує запропонований час, Ви можете обрати інші час і дату',
    ticket_amount: 'Талонів на сьогодні',
    free: 'Вільних',
  },
  message: {
    success: 'Успіх',
    fail: 'Невдача',
    queue_service_error: 'На даний час неможливо записатись в чергу на послугу \'{name}\'',
    load_error: 'Не вдалось завантажити дані',
    server_error: 'Втрачено зв\'язок з сервером',
    info_error: 'Щось пішло не так... Спробуйте звернутись пізніше'
  },
  breadcrumb: {
    'queue-period': 'Дата та час',
    'queue-form': 'Введення даних',
    'queue-results': 'Результати',
    'queue-tree': 'Вибір послуги',
  },
  result: {
    datePeriod: 'Дата',
    organizationId: 'Організація',
    regionId: 'Регіон',
    region: 'Регіон',
    serviceId: 'Послуга',
    servicePackageId: 'Пакет послуг',
    timePeriod: 'Час',
    current: 'Тип черги'
  },
  errors: {
    ERROR_LOGIN: 'Помилка при авторизації',
    ERROR_NO_SSE: 'Немає sse',
    ERROR_REGION_SELECT: 'Не обраний регіон',
    ERROR_NO_REGION: 'Немає регіонів',
    ERROR_ORGANIZATION_SELECT: 'Не обрана організація',
    ERROR_NO_ORGANIZATION: 'Немає організацій',
    ERROR_NO_SERVICE_PACKAGE: 'Немає пакетів послуг',
    ERROR_NO_SERVICE_PACKAGE_ITEM: 'Немає компонентів пакетів послуг',
    ERROR_SERVICE_PACKAGE_NOT_AVAILABLE: 'Пакет послуг недоступний',
    ERROR_SERVICE_SELECT: 'Не обрана послуга',
    ERROR_NO_SERVICE: 'Немає послуг',
    ERROR_DATE_SELECT: 'Не обрана дата',
    ERROR_TIME_RESERVED: 'Час зарезервовано',
    ERROR_TIME_SELECT: 'Не обрано час',
    ERROR_NO_CLIENT: 'Немає клієнтів',
    ERROR_CLIENT_SELECT: 'Не обраний клієнт',
    ERROR_NO_OPERATOR: 'Немає операторів',
    ERROR_OPERATOR_SELECT: 'Не обраний оператор',
    ERROR_NO_SCHEDULE: 'Немає розкладів',
    ERROR_SCHEDULE_SELECT: 'Не обрано розклад',
    ERROR_NO_SIGNATURE: 'Немає сигнатури',
    ERROR_SIGNATURE_SELECT: 'Не обрана сигнатура',
    ERROR_REQUIRED: 'Введені не всі необхідні дані',
    ERROR_SHORT_TEXT: 'Помилка: SHORT_TEXT',
    ERROR_PHONE: 'Невірний номер телефону',
    ERROR_EMAIL: 'Невірна адреса електронної пошти',
    ERROR_CHECK_LIMIT: 'Перевищений ліміт. Кількість спроб вичерпано',
    ERROR_CHECK_LIMIT_FIO:
      'Дані, які Ви ввели для отримання талона в електронну чергу на цю дату вже використовуються для іншого запису. Рекомендуємо здійснити спробу запису в електронну чергу на іншу дату.',
    ERROR_CHECK_LIMIT_FIO_ONLY: 'На жаль, система не може здійснити запис з такими даними в електронну чергу.' +
      '<br/>' +
      '<span class="smaller">Рекомендуємо перевірити прізвище, вказане при реєстрації, адже воно має бути введене українською мовою з великої літери без жодних додаткових знаків.</span>' +
      '<br/>' +
      '<span class="smaller">Зверніть увагу, що дане прізвище, також, не може бути використане для запису в електронну чергу більше трьох разів на тиждень.</span>',
    //ERROR_CHECK_LIMIT_FIO_ONLY: 'Будь ласка, введіть винятково прізвище.',
    ERROR_ADD_RESERVATION: 'Час зарезервовано',
    disagree: 'Ви не підтвердили згоду на збір, обробку та використання персональних даних',
    datetime_choose: 'Ви не обрали час чи дату',
    time_exceeded: 'Час очікування вичерпаний',
  },
  units: {
    hours: 'год',
    minutes: 'хв',
  },


  hTerminal: 'термінал',

  mcError: 'На жаль, виникла помилка :(',
  mcDepositRequest: 'Внесіть вказану суму, будь ласка',
  mcPayRequest: 'Сплатіть вказану суму, будь ласка',
  mhTargetRequest: 'Будь ласка, оберіть ціль Вашого звернення',
  mhQrScanner: 'Будь ласка, відскануйте QR-код Вашого звернення, щоб перейти до оплати',
  mhQrCam: 'Будь ласка, піднесіть Ваш QR-код до камери',
  mhOr: 'або оберіть ціль вашого звернення',
  mhMoveQrCode: 'Піднесіть надрукований QR-код Вашого звернення до сканера',
  mhError: 'На даний момент реєстрація на обрану послугу не доступна. Зверніться, будь ласка, пізніше.',
  mhNotFound: 'На жаль, послуг за Вашим запитом не знайдено :(',
  mit: 'Введіть послугу',
  mitButtonName: 'Введіть секретний код',

  // Queue text values
  qType: 'Тип черги',
  qLiveRV: 'живу чергу',
  qOr: 'або',
  qFixed: 'На іншу дату та час',
  qFixed2: 'час на іншу дату',
  qAvailableTime: 'Доступний час запису на',
  qNow: 'Зараз',
  qNotToday1: 'На даний момент, талони до черги на дату та час, відсутні',
  qNotToday2: 'або стати в',
  qNotToday3: 'Будь ласка, візьміть талон до поточної черги',

  qLabel: 'Введіть прізвище, ім\'я та по батькові',
  qSubmit: 'Записатись',
  qTime: 'Час запису',
  qFio: 'ПІБ',
  qSuccess: 'Операція успішна! Отримайте ваш талон',
  qAdditionalInfo: 'Ви можете слідкувати за чергою у нашому чат-боті, на електронному табло та слухати оголошення',
  qOutOfTicket: 'На даний момент, вільні місця в електронній черзі відсутні. Зверніться, будь ласка, пізніше.',
  qNextQuestion: 'Наступне запитання',

  // Information text values; iq - info question, ia - info answer, ic - info caption
  iqLegalPersonName: 'Повне та скорочене найменування юридичної особи:',
  iqIdentityCode: 'Ідентифікаційний код:',
  iqLocation: 'Місцезнаходження:',
  iqContacts: 'Номер телефону та електронна адреса довідкової служби:',
  iqOperatingMode: 'Режим роботи платіжного пристрою:',
  iqPeriod: 'Строки зарахування коштів отримувачам:',
  iaLegalPersonName: 'АКЦІОНЕРНЕ ТОВАРИСТВО \'УНІВЕРСАЛ БАНК\' (АТ \'УНІВЕРСАЛ БАНК\')',
  iaIdentityCode: '21133352',
  iaLocation: '04114, м. Київ, вул. Автозаводська, буд. 54/19',
  iaContacts: '+380664700505, +380974700505, +380734700505; contact@universalbank.com.ua',
  iaOperatingMode: 'цілодобово',
  iaPeriod: 'Відповідно до Закону України «Про платіжні системи та переказ коштів в Україні» та Інструкції про ведення касових операцій банками в Україні, затвердженої постановою Правління Національного банку України від 25.09.2018 № 103',
  icInfo: 'Інформація про банк та термінал',

  // Rate text values; rc - rate caption, rh - rate help, rtd - rate <td>
  rc: 'Тарифний пакет',
  rh: 'Розмір комісійної винагороди Банку за переказ коштів для груп ПТКС (без ПДВ)',
  rtdFrom: 'Сума операції з ініціювання переказу, від, грн.',
  rtdTo: 'Сума операції з ініціювання переказу, до, грн.',
  rtdRate: 'Розмір комісійної винагороди за переказ коштів, *, **',
  rtdMinAmount: 'Мінімальна сума комісії, грн.',

  paymentRate: 'Платежі',
  mobileRate: 'Мобільний',
  cardRate: 'Карта',

  // Footer buttons text values
  fBack: 'Назад',
  fMain: 'Головна',
  fNext: 'Далі',
  fPayment: 'Оплата',
  fPay: 'Оплатити',
  fMenu: 'Меню',
  fExit: 'Вихід',

  // Table header text values
  tSum: 'Сума:',
  tCommission: 'Комісія:',
  tTotal: 'До сплати:',

  // Path text values
  pMain: 'Головна',
  pConfirmation: 'Підтвердження даних',
  pInput: 'Введення даних',
  pInfo: 'Згода на обробку персональних даних',
  pPayment: 'Платежі за послуги',
  pBasket: 'Корзина платежів',
  pDetails: 'Деталі платежу',
  pNotes: 'Внесення купюр',
  pPos: 'Безготівкова оплата',

  // Buttons text values
  bEdit: 'Редагувати',
  bSearch: 'Пошук',
  //bInfo: 'Згода на обробку персональних даних',
  //bRates: 'Інформація та тарифи',
  bShow: 'Показати',
  bHide: 'Сховати',
  bDetails: 'Детальніше',
  bAdd: 'Додати послугу',
  bBasket: 'Корзина платежів',
  bClose: 'Закрити',
  bClear: 'Очистити',
  bEnter: 'Увійти',
  bEnterSend: 'Ввести',
  bCash: 'Готівкова оплата',
  bPos: 'Безготівкова оплата',
  bQrScan: 'Відсканувати QR-код',
  bDeleteAll: 'Очистити все',

  // Details text values
  dName: 'Назва послуги',
  dSum: 'Сума',
  dCommission: 'Комісія',
  dRecognition: 'Призначення платежу',
  dPayer: 'Платник',
  dReceiver: 'Отримувач',
  dBank: 'Банк',
  dAccount: 'Розрахунковий рахунок',
  dEdrpou: 'Код ЄДРПОУ',

  // Service-menu text values
  sCode: 'Введіть секретний код',
  sServ: 'Інженерне меню',
  sEnter: 'Увійти',
  sError: 'Помилка',

  // Receivers text values
  alreadyEntered: 'Вже внесено',
  remainToEnter: 'Залишилось внести',
  remainder: 'Залишок',
  transferToAccount: 'Перевести на рахунок',
  warning: 'Зверніть увагу, що термінал не видає решту',
  denominationMessage: 'Приймаються купюри номіналом',
  cardInstruction: 'Будь ласка, вставте картку в термінал, проведіть карткою або прикладіть картку до екрану терміналу (для карток з NFS)',
  qrError: 'Не вдалось завантажити QR',

  // Errors text values
  wrongPhone: 'Ви ввели невірний номер телефону. Виправте, будь ласка',
  wrongSum: 'Ви ввели невірну суму. Виправте, будь ласка',
  doublePhone: 'На жаль, не можливо здійснити поповнення одного номера телефону протягом 15 хвилин',

  // Timer text values
  timerQuestion: 'Ви ще тут?',
  timerYes: 'Так',

  // Popup caption, addition and button text values
  errorCaption: 'Помилка',
  successCaption: 'Успіх',
  offer: 'Бажаєте поповнити мобільний на суму залишку (+)?',
  successText: 'Операція оплати пройшла успішно',
  errorText: 'Виникла помилка: ',
  basketCleared: 'Корзина була успішно очищена',
  connectionEstablished: 'Зєднання успішно відновлено',
  yes: 'Так',
  no: 'Ні',

  // General values
  denomination: 'Номінал',
  count: 'Кількість',
  sum: 'Сума',
  total: 'Всього',
  bill: 'купюри',
  collection: 'Інкасація',
  currencyUAH: 'грн',
  hotline: 'Гаряча лінія',
  reload: 'ПЕРЕЗАВАНТАЖИТИ',
  close: 'Закрити',
  permissionPart1: 'Надаю згоду на збір, обробку та використання наданих мною персональних даних.',
  permissionPart2: 'З правами суб\'єкта персональних даних ознайомлений(на).',
  clickHere: 'Натискати тут',

  // Messages to display during waiting for server answer
  messStart: 'Запуск',
  messDataHandling: 'Обробка даних',
  messAddPayment: 'Здійснення оплати',
  messChangeLanguage: 'Зміна мови',

  messAddBasketItem: 'Додавання даних до корзини',
  messDeleteBasketItem: 'Видалення даних з корзини',
  messClearBasket: 'Очищення корзини',

  messCashCodeStart: 'Ввімкнення купюроприймача',
  messCashCodeEnd: 'Вимкнення купюроприймача',
  messQRStart: 'Ввімкнення QR-сканера',
  messQREnd: 'Вимкнення QR-сканера',

  messGetBasketInfo: 'Запит даних: вміст корзини',
  messGetCollectionInfo: 'Запит даних: інкасація',
  messGetEnabledBills: 'Запит даних: доступні для введення номінали',
  messGetInformationInfo: 'Запит даних: згода на обробку персональних даних',
  messGetMenuXml: 'Запит даних: меню',
  messGetRateInfo: 'Запит даних: інформація та тарифи',
  messGetRestAmount: 'Запит даних: доступний залишок',
  messGetScenarioInfo: 'Запит даних: питання',
  messGetTerminalInfo: 'Запит даних: про термінал',

  // Popup variants
  messPart1: 'Неможливо перейти в розділ',
  messPart2: 'так як у Вас є платежі в корзині',
  clearAndGo: 'Очистити і перейти до',
  goToCart: 'Перейти до корзини',
  goToMain: 'На головну',

  // Popup messages
  errKorzinaClear: 'Корзину НЕ очищено',
  errKorzinaDel: 'Не вдалось видалити платіж з корзини',
  errGetrestamount: 'Не вдалось отримати залишок',
  errCashcodestart: 'Купюроприймач не працює',
  errGetEnabledDenominations: 'Не вдалось отримати список номіналів, які підтримує термінал',
  errPrintrestcode: 'Не вдалось роздрукувати чек для залишку',
  errAddPlateg: 'Статус оплати невідомий. Зверніться на гарячу лінію:',
  errAddOper: 'Статус поповнення невідомий. Зверніться на гарячу лінію:',
  errEQueue: 'Не вдалось завантажити електронну чергу. Помилка: ',
  errNoCoupon: 'На жаль, немає вільних талонів',
  errPrintEquery: 'Не вдалось роздрукувати чек',
  errAddEquery: 'Не вдалось записатись в чергу',
  errCalcParam: 'Не вдалось обробити введені дані',
  errPosAddPlateg: 'Помилка отримання данних про сплату на пос-пристрої. Зверніться на гарячу лінію:',
  errTarifInfo: 'Не вдалось отримати інформацію про тарифи',
  errKorzinaInfo: 'Не вдалось отримати інформацію про корзину',
  errMenuXml: 'Не вдалось отримати головне меню',
  errGetterminfo: 'Не вдалось отримати інформацію про термінал',
  errEngineerMenuXml: 'Інженерне меню недоступне',
  errNotEnough: 'Внесено недостатньо коштів',
  errDynamicsCount: 'Не вдалось отримати кількість вільних місць для черги',
  errOvirPaylistXml: 'Не вдалось отримати параметри для введення',
  errTermsecretcode: 'Не вдалось перевірити пароль',
  errServiceMenu: 'Не вдалось обробити Ваш запит',
  errScenarioXml: 'Не вдалось отримати головне меню',
  errInfoSoglash: 'Не вдалось отримати інформацію про згоду на обробку даних',
  errKorzinaAdd: 'Платіж НЕ додано до корзини',
  errServiceClick: 'Неможливо перейти до наступної сторінки. Невірний ID кнопки: ',
  errHelp: 'Спробуйте перезавантажити або звернутись в службу підтримки',
  errTimeout: 'Час очікування вийшов',
  errConnection: 'Схоже відбувся розрив зв\'язку. Спробуйте перезавантажити термінал або зверніться в службу підтримки',
  errEqueryMenu: 'Не вдалось завантажити меню черги'
};
