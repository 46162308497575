import { createStore, Store as VuexStore, useStore as vuexUseStore  } from "vuex";
import createPersistedState from 'vuex-persistedstate';

import appStore, {AppStateInterface} from './app.store';
import terminalStore, {TerminalStateInterface} from './terminal.store';
import {InjectionKey} from "vue";

export interface StateInterface {
    appStore: AppStateInterface;
    terminalStore: TerminalStateInterface;
}
export const storeKey: InjectionKey<VuexStore<StateInterface>> = Symbol('vuex-key');

export function useStore() {
    return vuexUseStore(storeKey);
}
export default createStore<StateInterface>({
    modules: {
        appStore,
        terminalStore,
    },
    plugins: [
        createPersistedState({
            paths: ['appStore']
        }),
    ],
});
