<template>
  <div class="header__buttons-item">
    <div class="header__buttons-item__icon"></div>
    <p class="header__buttons-item__text">{{ 0 }},00 <span>грн</span></p>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.header__buttons-item {
  @extend .row-container;
  justify-content: space-around;
  flex: 1;
  height: $header;
  max-width: max-content;
  min-width: max(2.5rem, 8vw);

  background-color: $dark-gray;
  border: 1px solid $dark-gray;

  &__icon {
    width: 2.5rem;
    height: 100%;
    margin: 0 0.25rem;

    @include svgBg($shoppingCart, 1.5rem);
  }

  &__text {
    @extend .flex-col, .font-size-m;
    align-items: center;
    width: max-content;
    margin-right: 1rem;
    color: white;
    line-height: 1rem;

    & > span {
      @extend .font-size-xs;
    }
  }
}
</style>
