export const ru = {
  account: 'Счет',
  uah: '.-',
  helpline: 'Мы всегда рядом, звоните',
  search: 'Поиск',
  loading: 'Загрузка',
  timer_question: 'Вы еще здесь?',
  duration: 'Длительность',
  welcome_title: 'Добро пожаловать в терминал самообслуживания!',
  welcome_subtitle: 'Пожалуйста, выберите услугу',
  choose_service: 'Выберите услугу',
  error: 'Ошибка',
  result_ticket_num: 'Номер Вашего талона: {num}',
  ticket_num: 'Номер талона',
  terminal: 'Терминал',
  address: 'Адрес',
  help_line_phone: 'Телефон горячей линии',
  approximate_at: 'приблизительно в',
  confirmation: 'Подтверджение',
  attention: 'Внимание!',
  choose_time: 'Выбор времени',
  free_time: 'Свободное время',
  days: ['Пн', 'Вт',' Ср', 'Чт', 'Пт',' Сб', 'Вс'],
  header: {
    info_button: 'Согласие на обработку персональных данных',
    rates_button: 'Информация и тарифы',
  },
  footer: {
    button_back: 'Назад',
    button_main: 'Главная',
    button_next: 'Далее',
  },
  permission: {
    part_1: 'Предоставляю согласие на сбор, обработку и использование указанных мной персональных данных.',
    part_2: 'С правами субъекта персональных данных ознакомлен(на).',
  },
  button: {
    ok: 'Ок',
    nearest: 'Ближайшее время',
    edit: 'Редактировать',
    add_queue: 'Записаться',
    yes: 'Да',
    reload: 'Перезагрузить',
    to_main: 'На главную',
    permission: 'Согласие на обработку персональных данных',
    submit_queue_live: 'Подтвердить ближайшее время',
    cancel: 'Отмена',
    submit_time: 'Подтвердить время',
    other_time: 'Выбрать другое время',
    close: 'Закрыть',
    print: 'Распечатать талон',
  },
  queue: {
    available_time: 'Доступное время записи на',
    no_available_time: 'Нету доступного времени для записи на',
    fixed: 'На другую дату и время',
    live_1: 'Живая очередь',
    live_2: '(на сегодня)',
    no_near_1: 'На данный момент, талоны в текущую очередь отсутствуют',
    no_near_2: 'Пожалуйста выберете удобное для Вас время и дату',
    choose_period: 'Пожалуйста выберите вариант постановления в очередь',
    no_dates: 'Нету доступных дат',
    approximate_time: 'Приблизительное время ожидания',
    appearance_time: 'Ожидается появление талонов через',
    in_queue: 'Людей в очереди на выбранную услугу',
    current: 'Текущая',
    more_than: 'Более, чем',
    live_gotovo_1: 'Время указано ориентировочно!',
    live_gotovo_2: 'Не уходите с подразделения, Вас могут вызвать раньше.',
    live_gotovo_3: 'Следите за очередью на табло.',
    live_gotovo_4: 'Если Вас не устраивает предложенное время, Вы можете выбрать другое время и дату',
    ticket_amount: 'Талонов на сегодня',
    free: 'Свободных',
  },
  message: {
    success: 'Успех',
    fail: 'Неудача',
    queue_service_error: 'Сейчас невозможно записаться в очередь на услугу \'{name}\'',
    load_error: 'Не удалось загрузить данные',
    server_error: 'Потеряна связь с сервером',
    info_error: 'Что-то пошло не так... Попробуйте обратиться позже'
  },
  breadcrumb: {
    'queue-period': 'Дата и время',
    'queue-form': 'Введение данных',
    'queue-results': 'Результаты',
    'queue-tree': 'Выбор услуги',
  },
  result: {
    datePeriod: 'Дата',
    organizationId: 'Организация',
    regionId: 'Регион',
    region: 'Регион',
    serviceId: 'Услуга',
    servicePackageId: 'Пакет услуг',
    timePeriod: 'Время',
    current: 'Тип очереди'
  },
  errors: {
    ERROR_LOGIN: 'Ошибка авторизации',
    ERROR_NO_SSE: 'SSE не доступен',
    ERROR_REGION_SELECT: 'Не выбран регион',
    ERROR_NO_REGION: 'Нет регионов',
    ERROR_ORGANIZATION_SELECT: 'Не выбрана организация',
    ERROR_NO_ORGANIZATION: 'Нет организаций',
    ERROR_NO_SERVICE_PACKAGE: 'Нет пакетов услуг',
    ERROR_NO_SERVICE_PACKAGE_ITEM: 'Нет компонентов пакетов услуг',
    ERROR_SERVICE_PACKAGE_NOT_AVAILABLE: 'Пакет услуг не доступен',
    ERROR_SERVICE_SELECT: 'Не выбрана услуга',
    ERROR_NO_SERVICE: 'Нет услуг',
    ERROR_DATE_SELECT: 'Не выбрана дата',
    ERROR_TIME_RESERVED: 'Время зарезервировано',
    ERROR_TIME_SELECT: 'Не выбрано время',
    ERROR_NO_CLIENT: 'Нет клиентов',
    ERROR_CLIENT_SELECT: 'Не выбран клиент',
    ERROR_NO_OPERATOR: 'Нет операторов',
    ERROR_OPERATOR_SELECT: 'Не выбран оператор',
    ERROR_NO_SCHEDULE: 'Нет расписания',
    ERROR_SCHEDULE_SELECT: 'Не выбрано расписание',
    ERROR_NO_SIGNATURE: 'Нет сигнатуры',
    ERROR_SIGNATURE_SELECT: 'Не выбрана сигнатура',
    ERROR_REQUIRED: 'Введены не все необходимые данные',
    ERROR_SHORT_TEXT: 'Ошибка: SHORT_TEXT',
    ERROR_PHONE: 'Неверный номер телефона',
    ERROR_EMAIL: 'Неверный адрес электронной почты',
    ERROR_CHECK_LIMIT: 'Превышен лимит. Количество попыток исчерпано',
    ERROR_CHECK_LIMIT_FIO:
      'Данные, которые Вы ввели для получения талона в электронную очередь на эту дату уже используются для другой записи. Рекомендуем повторить попытку записи в электронную очередь на другую дату.',
    ERROR_CHECK_LIMIT_FIO_ONLY: 'К сожалению, система не может осуществить запись в электронную очередь с такими данными.' +
      '<br/>' +
      '<span class="smaller">Рекомендуем проверить фамилию, усказанную при регистрации, так как она должна быть введена на украинском языке с большой буквы без любых дополнительных знаков.</span>' +
      '<br/>' +
      '<span class="smaller">Обратите внимание, что данная фамилия, к тому же, не может быть использована для записи в электронную очередь больше трёх раз в неделю.</span>',
    //ERROR_CHECK_LIMIT_FIO_ONLY: 'Пожалуйста, введите исключительно фамилию.',
    ERROR_ADD_RESERVATION: 'Время зарезервировано',
    disagree: 'Вы не подтвердили соглашение на сбор, обработку и использование персональных данных',
    datetime_choose: 'Вы не выбрали дату или время',
    time_exceeded: 'Вышло время ожидания',
  },
  units: {
    hours: 'ч',
    minutes: 'мин',
  },


  mcError: 'К сожалению, возникла проблема :(',
  mcDepositRequest: 'Пожалуйста, внесите указанную сумму',
  mcPayRequest: 'Пожалуйста, оплатите услуги картой',
  mhTargetRequest: 'Пожалуйста, выберите цель Вашего обращения',
  mhQrScanner: 'Пожалуйста, отсканируйте QR-код Вашего обращения чтобы перейти к оплате',
  mhQrCam: 'Пожалуйста, поднесите Ваш QR-код к камере',
  mhOr: 'или выберите цель Вашего обращения',
  mhMoveQrCode: 'Поднесите напечатанный QR-код Вашего обращения к сканеру',
  mhError: 'На данный момент терминал не поддерживает этот вид оплаты. Попробуйте обратиться позже или воспользоваться другим',
  mhNotFound: 'К сожалению, по Вашему запросу услуг не найдено :(',
  mit: 'Введите услугу',
  mitButtonName: 'Введите секретный код',

  // Queue text values
  qType: "Тип очереди",
  qLiveRV: "живую очередь",
  qOr: "или",
  qFixed: "Фиксированное время",
  qFixed2: "время на другую дату",
  qNoNear2: "Пожалуйста выберете удобное для Вас",
  qNow: "Сейчас",
  qNotToday1: "К сожелению, невозможо записаться на фиксированное время",
  qNotToday2: "или стать в живую очередь",
  qNotToday3: "Вы можете стать в живую очередь",
  qLabel: "Введите фамилию, имя и отчество",
  qTime: "Время записи",
  qFio: "ФИО",
  qSuccess: "Операция прошла успешно",
  qAdditionalInfo: 'Вы можете следить за очередью в нашем чат-боте, на электронном табле и слушать оповещения',
  qNextQuestion: 'Следующий вопрос',

  // Information text values; iq - info question, ia - info answer, ic - info caption
  iqLegalPersonName: 'Повне та скорочене найменування юридичної особи:',
  iqIdentityCode: 'Ідентифікаційний код:',
  iqLocation: 'Місцезнаходження:',
  iqContacts: 'Номер телефону та електронна адреса довідкової служби:',
  iqOperatingMode: 'Режим роботи платіжного пристрою:',
  iqPeriod: 'Строки зарахування коштів отримувачам:',
  iaLegalPersonName: 'АКЦІОНЕРНЕ ТОВАРИСТВО "УНІВЕРСАЛ БАНК" (АТ "УНІВЕРСАЛ БАНК")',
  iaIdentityCode: '21133352',
  iaLocation: '04114, м. Київ, вул. Автозаводська, буд. 54/19',
  iaContacts: '+380664700505, +380974700505, +380734700505; contact@universalbank.com.ua',
  iaOperatingMode: 'цілодобово',
  iaPeriod: 'Відповідно до Закону України «Про платіжні системи та переказ коштів в Україні» та Інструкції про ведення касових операцій банками в Україні, затвердженої постановою Правління Національного банку України від 25.09.2018 № 103',
  icInfo: 'Інформація про банк та термінал',

  // Rate text values; rc - rate caption, rh - rate help, rtd - rate <td>
  rc: 'Тарифный пакет',
  rh: 'Розмір комісійної винагороди Банку за переказ коштів для груп ПТКС (без ПДВ)',
  rtdFrom: 'Сума операції з ініціювання переказу, від, грн.',
  rtdTo: 'Сума операції з ініціювання переказу, до, грн.',
  rtdRate: 'Розмір комісійної винагороди за переказ коштів, *, **',
  rtdMinAmount: 'Мінімальна сума комісії, грн.',

  paymentRate: 'Платежи',
  mobileRate: 'Мобильный',
  cardRate: 'Карта',

  // Footer buttons text values
  fPayment: 'Оплата',
  fPay: 'Оплатить',
  fMenu: 'Меню',
  fExit: 'Выход',

  // Table header text values
  tSum: 'Сумма:',
  tCommission: 'Комиссия:',
  tTotal: 'К оплате:',

  // Path text values
  pMain: 'Главная',
  pConfirmation: 'Подтверждение данных',
  pInput: 'Введение данных',
  pPayment: 'Платежи за услуги',
  pBasket: 'Корзина платежей',
  pDetails: 'Детально о платеже',
  pNotes: 'Внесения купюр',
  pPos: 'Безналичная оплата',

  // Buttons text values
  bShow: 'Показать',
  bHide: 'Свернуть',
  bDetails: 'Детальнее',
  bAdd: 'Добавить услугу',
  bBasket: 'Корзина платежей',
  bClose: 'Закрыть',
  bClear: 'Очистить',
  bEnter: 'Войти',
  bEnterSend: 'Ввести',
  bCash: 'Оплата наличными',
  bPos: 'Оплата картой',
  bQrScan: 'Отсканировать QR-код',
  bDeleteAll: 'Очистить все',

  // Details text values
  dName: 'Название услуги',
  dSum: 'Сумма',
  dCommission: 'Комиссия',
  dRecognition: 'Назначение платежа',
  dPayer: 'Плательщик',
  dReceiver: 'Получатель',
  dBank: 'Банк',
  dAccount: 'Рассчетный счет',
  dEdrpou: 'Код ЕДРПОУ',

  // Service-menu text values
  sCode: "Введите секретный код",
  sServ: "Инженерное меню",
  sEnter: "Ввойти",
  sError: 'Ошибка',

  // Receivers text values
  alreadyEntered: 'Уже внесено',
  remainToEnter: 'Осталось внести',
  remainder: 'Остаток',
  transferToAccount: 'Перевести на счет',
  warning: 'Обратите внимание, что терминал не выдает сдачу',
  denominationMessage: 'Принимаются купюры номиналом',
  cardInstruction: 'Пожалуйста, вставьте карту в терминал, проведите картой или приложите карту к экрану терминала (если карта с NFS)',

  // Errors text values
  wrongPhone: 'Вы ввели неправильный номер телефона. Исправьте, пожалуйста',
  wrongSum: 'Вы ввели некорректную сумму. Исправьте, пожалуйста',
  doublePhone: 'Нельзя пополнять тот же номер телефона в течение 15 минут',
  qrError: 'Не удалось загрузить QR',

  // Popup caption, addition and button text values
  errorCaption: 'Ошибка',
  successCaption: 'Успех',
  offer: 'Желаете пополить мобильный на сумму остатка (+)?',
  successText: 'Операция оплаты прошла успешно',
  errorText: 'Возникла ошибка: ',
  basketCleared: 'Корзина была успешно очищена',
  connectionEstablished: 'Соединение успешно восстановлено',
  yes: 'Да',
  no: 'Нет',

  // General values
  denomination: 'Номинал',
  count: 'Количество',
  sum: 'Сумма',
  total: 'Всего',
  bill: 'купюры',
  collection: 'Инкассация',
  hotline: 'Горячая линия',
  reload: 'ПЕРЕЗАГРУЗИТЬ',
  close: 'Закрыть',

  // Messages to display during waiting for server answer
  messStart: 'Запуск',
  messDataHandling: 'Обработка данных',
  messAddPayment: 'Добавление платежа',
  messChangeLanguage: 'Изменение языка',

  messAddBasketItem: 'Добавление данных в корзину',
  messDeleteBasketItem: 'Удаление данных с корзины',
  messClearBasket: 'Очистка корзины',

  messCashCodeStart: 'Включение купюропремника',
  messCashCodeEnd: 'Выключение купюроприемника',
  messQRStart: 'Включение QR-сканнера',
  messQREnd: 'Выключение QR-сканнера',

  messGetBasketInfo: 'Запрос данных: компоненты корзины',
  messGetCollectionInfo: 'Запрос данных: инкассация',
  messGetEnabledBills: 'Запрос данных: доступные для введения номиналы',
  messGetInformationInfo: 'Запрос данных: согласие на обработку персональных данных',
  messGetMenuXml: 'Запрос данных: меню',
  messGetRateInfo: 'Запрос данных: Информация и тарифы',
  messGetRestAmount: 'Запрос данных: остаток',
  messGetScenarioInfo: 'Запрос данных: вопросы',
  messGetTerminalInfo: 'Запрос данных: данные терминала',

  // Popup variants
  messPart1: 'Невозможно перейти в раздел',
  messPart2: 'так как у Вас есть платежи в корзине',
  clearAndGo: 'Очистить и перейти к',
  goToCart: 'Перейти к корзине',
  goToMain: 'На главную',

  // Popup messages
  errKorzinaClear: 'Корзину НЕ очищено',
  errKorzinaDel: 'Не удалось удалить платеж из корзины',
  errGetrestamount: 'Не удалось получить остаток',
  errCashcodestart: 'Купюроприемник не работает',
  errGetEnabledDenominations: 'Не удалось получить список номиналов, которые поддерживает терминал',
  errPrintrestcode: 'Не удалось распечатать чек остатка',
  errAddPlateg: 'Статус оплаты неизвестен. Обратитесь на горячую линию:',
  errAddOper: 'Статус пополнения невідомий. Зверніться на гарячу лінію:',
  errEQueue: 'Не удалось загрузить электронную очередь. Ошибка: ',
  errNoCoupon: 'К сожалению, нет свободных талонов',
  errPrintEquery: 'Не удалось распечатать чек',
  errAddEquery: 'Не удалось записаться в очередь',
  errCalcParam: 'Не удалось обработать введенные данные',
  errPosAddPlateg: 'Ошибка при получении данных про оплату на пос-устройстве. Обратитесь на горячую линию:',
  errTarifInfo: 'Не удалось получить информацию о тарифах',
  errKorzinaInfo: 'Не удалось получить информацию о корзине',
  errMenuXml: 'Не удалось получить главное меню',
  errGetterminfo: 'Не удалось получить информацию о термінале',
  errEngineerMenuXml: 'Инженерное меню недоступно',
  errNotEnough: 'Внесено недостаточно средств',
  errDynamicsCount: 'Не удалось получить количество свободных мест в очереди',
  errOvirPaylistXml: 'Не удалось получить параметры для ввода',
  errTermsecretcode: 'Не удалось проверить пароль',
  errServiceMenu: 'Не удалось обработать Ваш запрос',
  errScenarioXml: 'Не удалось получить главное меню',
  errInfoSoglash: 'Не удалось получить информацию о согласии на обработку данных',
  errKorzinaAdd: 'Платеж НЕ добавлено в корзину',
  errServiceClick: 'Невозможно перейти к следующей странице. Неверный ID кнопки: ',
  errHelp: 'Попробуйте перезагрузить или обратиться в службу поддержки',
  errTimeout: 'Время ожидания вышло',
  errConnection: 'Похоже произошел разрыв связи. Попробуйте перезагрузить терминал или обратитесь в службу поддержки',
  errEqueryMenu: 'Не удалось загрузить меню очереди'
};
