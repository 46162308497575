import { Key } from "@/types/Keyboard";

export const eng: Key[][] = [
    [
        { symbol: 'Q' },
        { symbol: 'W' },
        { symbol: 'E' },
        { symbol: 'R' },
        { symbol: 'T' },
        { symbol: 'Y' },
        { symbol: 'U' },
        { symbol: 'I' },
        { symbol: 'O' },
        { symbol: 'P' },
        { symbol: '‘' },
    ], [
        { symbol: 'A' },
        { symbol: 'S' },
        { symbol: 'D' },
        { symbol: 'F' },
        { symbol: 'G' },
        { symbol: 'H' },
        { symbol: 'J' },
        { symbol: 'K' },
        { symbol: 'L' },
        { symbol: '-' },
        { symbol: '', action: 'backspace' }
    ], [
        { symbol: 'Z' },
        { symbol: 'X' },
        { symbol: 'C' },
        { symbol: 'V' },
        { symbol: 'B' },
        { symbol: 'N' },
        { symbol: 'M' },
        { symbol: ',' },
        { symbol: '.' },
        { symbol: '_' },
        { symbol: 'en', action: 'language' },
    ], [
        { symbol: '№' },
        { symbol: '/' },
        { symbol: '@' },
        { symbol: '', action: 'backward' },
        { symbol: ' ', size: 5 },
        { symbol: '', action: 'forward' },
        { symbol: '', action: 'numbers' },
    ]
];