import { Key } from "@/types/Keyboard";

export const rus: Key[][] = [
    [
        { symbol: 'Й' },
        { symbol: 'Ц' },
        { symbol: 'У' },
        { symbol: 'К' },
        { symbol: 'Е' },
        { symbol: 'Н' },
        { symbol: 'Г' },
        { symbol: 'Ш' },
        { symbol: 'Щ' },
        { symbol: 'З' },
        { symbol: 'Х' },
        { symbol: 'Ъ' },
    ], [
        { symbol: 'Ф' },
        { symbol: 'Ы' },
        { symbol: 'В' },
        { symbol: 'А' },
        { symbol: 'П' },
        { symbol: 'Р' },
        { symbol: 'О' },
        { symbol: 'Л' },
        { symbol: 'Д' },
        { symbol: 'Ж' },
        { symbol: 'Э' },
        { symbol: '', action: 'backspace' },
    ], [
        { symbol: 'Я' },
        { symbol: 'Ч' },
        { symbol: 'С' },
        { symbol: 'М' },
        { symbol: 'И' },
        { symbol: 'Т' },
        { symbol: 'Ь' },
        { symbol: 'Б' },
        { symbol: 'Ю' },
        { symbol: 'Ё' },
        { symbol: '-' },
        { symbol: '‘' },
    ], [
        { symbol: '№' },
        { symbol: '/' },
        { symbol: '@' },
        { symbol: '.' },
        { symbol: '', action: 'backward' },
        { symbol: ' ', size: 4 },
        { symbol: '', action: 'forward' },
        { symbol: '', action: 'numbers' },
        { symbol: 'ru', action: 'language' },
    ]
];