export const en = {
  account: 'Account',
  uah: '.-',
  helpline: 'We\'re always here, just call us',
  search: 'Search',
  loading: 'Loading data',
  timer_question: 'Are you still here?',
  duration: 'Duration',
  welcome_title: 'Welcome to self-service terminal!',
  welcome_subtitle: 'Please select the service you require',
  choose_service: 'Choose service',
  error: 'Error',
  result_ticket_num: 'Your ticket number is {num}',
  ticket_num: 'Ticket number',
  terminal: 'Terminal',
  address: 'Address',
  help_line_phone: 'Help line phone',
  approximate_at: 'approximate at',
  confirmation: 'Confirmation',
  attention: 'Attention!',
  choose_time: 'Choose time',
  free_time: 'Free time',
  days: ['Mon', 'Tue',' Wed', 'Thu', 'Fri',' Sat', 'Sun'],
  header: {
    info_button: 'Consent to the processing of personal data',
    rates_button: 'Info and rates',
  },
  footer: {
    button_back: 'Back',
    button_main: 'Main',
    button_next: 'Next',
  },
  permission: {
    part_1: 'I agree the collection, processing and using personal data provided by me.',
    part_2: 'I\'m familiar with the rights of the personal data subject.',
  },
  button: {
    ok: 'Ok',
    nearest: 'Nearest time',
    edit: 'Edit',
    add_queue: 'Submit',
    yes: 'Yes',
    reload: 'Reload',
    to_main: 'To main page',
    permission: 'Consent to the processing of personal data',
    submit_queue_live: 'Submit nearest time',
    cancel: 'Cancel',
    submit_time: 'Submit time',
    other_time: 'Choose other time',
    close: 'Close',
    print: 'Print ticket',
  },
  queue: {
    available_time: 'Available recording times on',
    no_available_time: 'No available recording times on',
    fixed: 'Fixed time',
    live_1: 'Live queue',
    live_2: '(today)',
    no_near_1: 'Unfortunately, there is no available place for today',
    no_near_2: 'Please choose from available',
    choose_period: 'Please select a queuing option',
    no_dates: 'No available dates',
    approximate_time: 'Time to wait',
    appearance_time: 'Tickets are expected in',
    in_queue: 'People in queue for selected service',
    current: 'Live',
    more_than: 'More than',
    live_gotovo_1: 'The time indicated is indicative!',
    live_gotovo_2: 'Do not leave the unit, you may be called earlier.',
    live_gotovo_3: 'Watch the queue on the board.',
    live_gotovo_4: 'If you are not satisfied with the proposed time, you can choose another time and date',
    ticket_amount: 'Today\'s tickets amount',
    free: 'Free',
  },
  message: {
    success: 'Success',
    fail: 'Fail',
    queue_service_error: 'It is not possible to choose service \'{name}\'',
    load_error: 'Could not load data',
    server_error: 'Can not connect to server',
    info_error: 'Something went wrong... Try again later'
  },
  breadcrumb: {
    'queue-period': 'Period',
    'queue-form': 'Data input',
    'queue-results': 'Results',
    'queue-tree': 'Services',
  },
  result: {
    datePeriod: 'Date',
    organizationId: 'Organization',
    regionId: 'Region',
    region: 'Region',
    serviceId: 'Service',
    servicePackageId: 'Service package',
    timePeriod: 'Time',
    current: 'Queue type'
  },
  errors: {
    ERROR_LOGIN: 'Login error',
    ERROR_NO_SSE: 'No sse',
    ERROR_REGION_SELECT: 'Region is not selected',
    ERROR_NO_REGION: 'No regions',
    ERROR_ORGANIZATION_SELECT: 'Organization is not selected',
    ERROR_NO_ORGANIZATION: 'No organizations',
    ERROR_NO_SERVICE_PACKAGE: 'No service packages',
    ERROR_NO_SERVICE_PACKAGE_ITEM: 'No service package items',
    ERROR_SERVICE_PACKAGE_NOT_AVAILABLE: 'Service package is not available',
    ERROR_SERVICE_SELECT: 'Service is not selected',
    ERROR_NO_SERVICE: 'No services',
    ERROR_DATE_SELECT: 'Date is not selected',
    ERROR_TIME_RESERVED: 'Time is reserved',
    ERROR_TIME_SELECT: 'Time is not selected',
    ERROR_NO_CLIENT: 'No clients',
    ERROR_CLIENT_SELECT: 'Client is not selected',
    ERROR_NO_OPERATOR: 'No operators',
    ERROR_OPERATOR_SELECT: 'Operator is not selected',
    ERROR_NO_SCHEDULE: 'No schedules',
    ERROR_SCHEDULE_SELECT: 'Schedule is not selected',
    ERROR_NO_SIGNATURE: 'No signature',
    ERROR_SIGNATURE_SELECT: 'Signature is not selected',
    ERROR_REQUIRED: 'Please, fill all required values',
    ERROR_SHORT_TEXT: 'Error: SHORT_TEXT',
    ERROR_PHONE: 'Incorrect phone',
    ERROR_EMAIL: 'Incorrect email',
    ERROR_CHECK_LIMIT: 'Exceeded limit. Number of attempts exhausted',
    ERROR_CHECK_LIMIT_FIO:
      'The data you entered to receive the ticket in the electronic queue on this date is already used for another record. We recommend you to attempt to write to the electronic queue on another date.',
    ERROR_CHECK_LIMIT_FIO_ONLY: 'Unfortunately, the system cannot add such data to the electronic queue.' +
      '<br/>' +
      '<span class="smaller">We recommend that you check the surname specified during registration, because it must be entered in Ukrainian with a capital letter without any additional characters.</span>' +
      '<br/>' +
      '<span class="smaller">Please note that this surname, cannot be used to write to the electronic queue more than three times a week too.</span>',
    //ERROR_CHECK_LIMIT_FIO_ONLY: 'Please enter your last name only.',
    ERROR_ADD_RESERVATION: 'This time is reserved',
    disagree: 'You did not confirmed the agreement to collect, process and use personal data',
    datetime_choose: 'You did not choose a date or time',
    time_exceeded: 'Waiting time exceeded',
  },
  units: {
    hours: 'h',
    minutes: 'min',
  },


  hTerminal: 'terminal',
  // Path text values
  pMain: 'Main',
  pConfirmation: 'Data confirmation',
  pInput: 'Input data',
  pInfo: 'Consent to the processing of personal data',
  pPayment: 'Payments for services',
  pBasket: 'Payments basket',
  pDetails: 'Payment details',
  pNotes: 'Making notes',
  pPos: 'Card payment',

  mcError: 'Unfortunately, there was an error :(',
  mcDepositRequest: 'Please enter the mentioned amount',
  mcPayRequest: 'Please pay the mentioned amount',
  mhTargetRequest: 'Please select the aim of your appeal',
  mhQrScanner: 'Please scan your QR-code if you want to go to the payment',
  mhQrCam: 'Please move your QR-code to the camera',
  mhOr: 'or select the service you require',
  mhMoveQrCode: 'Move your printed QR-code to scanner',
  mhError: 'Terminal is not support this kind of payment at that moment. Try later or use another one',
  mhNotFound: 'Unfortunately, service you searched was not found :(',
  mit: 'Enter the service name',
  mitButtonName: 'Enter the secret code',

  // Queue text values
  qType: 'Queue type',
  qLiveRV: 'live queue',
  qOr: 'or',
  qNow: 'Now',
  qNotToday1: 'Unfortunately, it is impossible to sign up for a fixed time',
  qNotToday2: 'or become a live queue',
  qNotToday3: 'You can queue up live',

  qLabel: 'Enter your full name',
  qSubmit: 'Sign up',
  qTime: 'Sign up time',
  qFio: 'Full name',
  qSuccess: 'Operation success',
  qAdditionalInfo: 'You can follow the queue in our chat-bot, on the electronic board and listen to the warnings',
  qNextQuestion: 'Next question',

  // Information text values; iq - info question, ia - info answer, ic - info caption
  iqLegalPersonName: 'Повне та скорочене найменування юридичної особи:',
  iqIdentityCode: 'Ідентифікаційний код:',
  iqLocation: 'Місцезнаходження:',
  iqContacts: 'Номер телефону та електронна адреса довідкової служби:',
  iqOperatingMode: 'Режим роботи платіжного пристрою:',
  iqPeriod: 'Строки зарахування коштів отримувачам:',
  iaLegalPersonName: 'АКЦІОНЕРНЕ ТОВАРИСТВО \'УНІВЕРСАЛ БАНК\' (АТ \'УНІВЕРСАЛ БАНК\')',
  iaIdentityCode: '21133352',
  iaLocation: '04114, м. Київ, вул. Автозаводська, буд. 54/19',
  iaContacts: '+380664700505, +380974700505, +380734700505; contact@universalbank.com.ua',
  iaOperatingMode: 'цілодобово',
  iaPeriod: 'Відповідно до Закону України «Про платіжні системи та переказ коштів в Україні» та Інструкції про ведення касових операцій банками в Україні, затвердженої постановою Правління Національного банку України від 25.09.2018 № 103',
  icInfo: 'Інформація про банк та термінал',

  // Rate text values; rc - rate caption, rh - rate help, rtd - rate <td>
  rc: 'Tariff Plan',
  rh: 'Розмір комісійної винагороди Банку за переказ коштів для груп ПТКС (без ПДВ)',
  rtdFrom: 'Сума операції з ініціювання переказу, від, грн.',
  rtdTo: 'Сума операції з ініціювання переказу, до, грн.',
  rtdRate: 'Розмір комісійної винагороди за переказ коштів, *, **',
  rtdMinAmount: 'Мінімальна сума платежу',

  paymentRate: 'Payment',
  mobileRate: 'Mobile',
  cardRate: 'Card',

  // Footer buttons text values
  fBack: 'Back',
  fMain: 'Main',
  fNext: 'Next',
  fPayment: 'Payment',
  fPay: 'Pay',
  fMenu: 'Menu',
  fExit: 'Exit',

  // Table header text values
  tSum: 'Sum:',
  tCommission: 'Commission:',
  tTotal: 'Total:',


  // Buttons text values
  bEdit: 'Edit',
  bSearch: 'Search',
  //bInfo: 'Consent to the processing of personal data',
  //bRates: 'Info and rates',
  bShow: 'Show',
  bHide: 'Hide',
  bDetails: 'Details',
  bAdd: 'Add service',
  bBasket: 'Shopping card',
  bClose: 'Close',
  bClear: 'Clear',
  bEnter: 'Enter',
  bEnterSend: 'Enter',
  bCash: 'Cash payment',
  bPos: 'Payment with a card',
  bQrScan: 'Scan QR-code',
  bDeleteAll: 'Clear All',

  // Details text values
  dName: 'Service name',
  dSum: 'Sum',
  dCommission: 'Commission',
  dRecognition: 'Payment recognition',
  dPayer: 'Payer',
  dReceiver: 'Receiver',
  dBank: 'Bank',
  dAccount: 'Account',
  dEdrpou: 'EDRPOU code',

  // Service-menu text values
  sCode: 'Enter secret code',
  sServ: 'Service menu',
  sEnter: 'Enter',
  sError: 'Error',

  // Receivers text values
  alreadyEntered: 'Already entered',
  remainToEnter: 'Remain to enter',
  remainder: 'Remainder',
  transferToAccount: 'Transfer to account',
  warning: 'Pay attention that terminal does not give the remainder',
  denominationMessage: 'Terminal can take banknotes with denominations of',
  cardInstruction: 'Please insert card into terminal, swipe card or tap the card over the contactless symbol on the terminal (for cards with NFS)',

  // Errors text values
  wrongPhone: 'You have entered incorrect phone number. Please fix it',
  wrongSum: 'You have entered the wrong sum amount. Please fix it',
  doublePhone: 'You can`t recharge the same phone number during 15 minutes',
  qrError: 'Cannot load QR',

  // Timer text values

  timerYes: 'Yes',

  // Popup caption, addition and button text values
  errorCaption: 'Error',
  successCaption: 'Success',
  offer: 'Do you want to replenish the mobile by a rest amount (+)?',
  successText: 'Payment transaction was done successfully',
  errorText: 'An error occurred: ',
  basketCleared: 'Shopping card was successfully cleared',
  connectionEstablished: 'Connection was successfully established',
  yes: 'Yes',
  no: 'No',

  // General values
  denomination: 'Denomination',
  count: 'Count',
  sum: 'Sum',
  total: 'Total',
  bill: 'bill',
  collection: 'Collection',
  currencyUAH: 'uah',
  hotline: 'Hotline',
  reload: 'RELOAD',
  close: 'Close',


  // Messages to display during waiting for server answer
  messStart: 'Starting',
  messDataHandling: 'Data handling',
  messAddPayment: 'Adding payment',
  messChangeLanguage: 'Changing language',

  messAddBasketItem: 'Adding data to basket',
  messDeleteBasketItem: 'Basket item data deletion',
  messClearBasket: 'Basket data deletion',

  messCashCodeStart: 'Turn ON cash acceptor',
  messCashCodeEnd: 'Turn OFF cash acceptor',
  messQRStart: 'Turn ON QR-code reader',
  messQREnd: 'Turn OFF QR-code reader',

  messGetBasketInfo: 'Data request: basket info',
  messGetCollectionInfo: 'Data request: collection info',
  messGetEnabledBills: 'Data request: available denominations',
  messGetInformationInfo: 'Data request: consent to the processing of personal data',
  messGetMenuXml: 'Data request: menu info',
  messGetRateInfo: 'Data request: rates and info',
  messGetRestAmount: 'Data request: available rest amount',
  messGetScenarioInfo: 'Data request: question info',
  messGetTerminalInfo: 'Data request: terminal info',

  // Popup variants
  messPart1: 'Failed to go to',
  messPart2: 'because you have payments in your cart',
  clearAndGo: 'Clear cart and go to',
  goToCart: 'Go to cart',
  goToMain: 'Go to main',

  // Popup messages
  errKorzinaClear: 'Cart WAS NOT cleared',
  errKorzinaDel: 'Failed to delete payment from cart',
  errGetrestamount: 'Failed to get rest amount',
  errCashcodestart: 'Bill acceptor does not work properly',
  errGetEnabledDenominations: 'Failed to get list of enabled denominations',
  errPrintrestcode: 'Failed to print check with rest',
  errAddPlateg: 'Payment status is unknown. Contact the hotline:',
  errAddOper: 'Mobile recharge status is unknown. Contact the hotline:',
  errEQueue: 'Failed to load EQueue data. Error: ',
  errNoCoupon: 'Unfortunately, there is no free coupons',
  errPrintEquery: 'Failed to print check',
  errAddEquery: 'Failed to sign up',
  errCalcParam: 'Failed to process data',
  errPosAddPlateg: 'Failed to get data about POS payment. Contact the hotline:',
  errTarifInfo: 'Failed to get tariff information',
  errKorzinaInfo: 'Failed to get cart info',
  errMenuXml: 'Failed to get main menu',
  errGetterminfo: 'Failed to get terminal info',
  errEngineerMenuXml: 'Engineering menu is not available',
  errNotEnough: 'Insufficient funds',
  errDynamicsCount: 'Failed to get amount of free query coupons',
  errOvirPaylistXml: 'Failed to get input parameters',
  errTermsecretcode: 'Failed to check the password',
  errServiceMenu: 'Failed to process your request',
  errScenarioXml: 'Failed to get main menu',
  errInfoSoglash: 'Failed to get info about data processing agreement',
  errKorzinaAdd: 'Payment WAS NOT added to cart',
  errServiceClick: 'Can not go to the next page. Invalid button ID: ',
  errHelp: 'Try rebooting or contacting the support team',
  errTimeout: 'Timeout out',
  errConnection: 'It looks like there was a disconnection. Try rebooting the terminal or contacting the support team',
  errEqueryMenu: 'Can not load equery menu'
};
