import { request } from "@/services/main.service";
import { Info } from "@/types/Info";
import { Organization } from "@/types/Organization";
import { Service } from "@/types/Service";
import { Results } from "@/types/Results";
import { ServicePackage } from "@/types/ServicePackage";
import { PrintData } from '@/types/Print';

const initialize = () => request({
    url: '/initialize',
    method: 'get',
    isGlobalApi: false
});

const printDocument = (data: PrintData) => request({
    url: '/print',
    method: 'post',
    isGlobalApi: false,
    data: data as Record<string, unknown>,
});

const getTQueue = () => request({
    url: ``,
    method: 'get',
});

const getTQueueInfo = (): Promise<Info> => request({
    url: `/info`,
    method: 'get',
});

const getTQueueOrganization = (): Promise<Organization> => request({
    url: `/organization`,
    method: 'get',
});

const getTQueueServices = (): Promise<Service[]> => request({
    url: `/service`,
    method: 'get',
});

const setTQueueService = (serviceId: number): Promise<Results> => request({
    url: `/service/${serviceId}`,
    method: 'get',
});

const getTQueueServicePackages = (): Promise<ServicePackage[]> => request({
    url: `/service-package`,
    method: 'get',
});

const setTQueueServicePackage = (packageId: number): Promise<Results> => request({
    url: `/service-package/${packageId}`,
    method: 'get',
});

const getTQueueDatePeriods = () => request({
    url: `/date-periods`,
    method: 'get',
});

const setTQueueDatePeriod = (dateId: string) => request({
    url: `/date-period/${dateId}`,
    method: 'get',
});

const getTQueueTimePeriods = () => request({
    url: `/time-periods`,
    method: 'get',
});

const setTQueueTimePeriod = (timeId: string) => request({
    url: `/time-period/${timeId}`,
    method: 'get',
});

const getTQueueForm = () => request({
    url: `/form`,
    method: 'get',
});

const addQueue = (data = {}) => request({
    url: `/add-queue`,
    method: 'post',
    data
});

const addLiveQueue = () => request({
    url: `/current-queue`,
    method: 'get',
});

const checkQueue = () => request({
    url: `/check-queue`,
    method: 'get',
});

export {
    initialize,
    printDocument,
    getTQueue,
    getTQueueInfo,
    getTQueueOrganization,
    getTQueueServices,
    setTQueueService,
    getTQueueServicePackages,
    setTQueueServicePackage,
    getTQueueDatePeriods,
    setTQueueDatePeriod,
    getTQueueTimePeriods,
    setTQueueTimePeriod,
    getTQueueForm,
    addQueue,
    addLiveQueue,
    checkQueue,
}
